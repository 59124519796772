import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { useState } from "react";
import ResultListView from "./ResultListView";
import ManageResultDetailView from "./ManageResultDetailView";
import { Result } from "src/redux/types/exam-submission";
import { useDispatch } from "react-redux";
import { setCurrentResult } from "src/redux/reducers";


type View = "list" | "detail"

export const ManageResultsView = () => {
  
  const [view, setView] = useState<View>("list");

  const dispatch = useDispatch()

  const defaultView = () => {
    setView("list");
  };

  const onClickDetail = (currentResult: Result) => {
    dispatch(setCurrentResult(currentResult));
    setView("detail");
  }

  const renderView = () => {
    switch (view) {
      case "list":
        return (
          <ResultListView onClickDetail={onClickDetail}/>
        )
      case "detail":
        return (
          <ManageResultDetailView defaultView = {defaultView}/>
        )
    }
  }
  
  return (
    <DashboardDefaultContainer>
      {renderView()}
    </DashboardDefaultContainer>
  );
};
