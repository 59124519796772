import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "src/components/dialogue/radix-dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { proctorsNoteApi } from "src/redux/api/_exams.api";

interface SelectNoteDialogProps {
  children: React.ReactNode;
  scheduleId: number;
  status: string;
  isMissed: boolean;
}

interface FormProps {
  appeared: string;
  missed: string;
}

export const SelectNoteDialog = ({
  children,
  scheduleId,
  status,
  isMissed,
}: SelectNoteDialogProps) => {
  const form = useForm<FormProps>();

  const submitProctorsNote = async (values: FormProps) => {
    if (status === "APPEARED") {
      const response = await proctorsNoteApi(scheduleId, values.appeared);
      if(response?.data.message.toLowerCase() === "success"){
        toast.success(response.data.data.msg)
      }
    }
    if (isMissed) {
      const response = await proctorsNoteApi(scheduleId, values.missed);
      if (response?.data.message.toLowerCase() === "success") {
        toast.success(response.data.data.msg);
      }
    }
  };

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Select Proctors Note</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="flex flex-col gap-y-2">
            <div>
              {status === "APPEARED" && (
                <FormField
                  name="appeared"
                  defaultValue={undefined}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Appeared</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Note" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Seen in Zoom">
                            Seen in Zoom
                          </SelectItem>
                          <SelectItem value="Not seen in Zoom">
                            Not Seen in Zoom
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              )}
              {isMissed && (
                <FormField
                  name="missed"
                  defaultValue={undefined}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Missed</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Note" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Tech issue">Tech Issue</SelectItem>
                          <SelectItem value="Not seen in Zoom">
                            Not Seen in Zoom
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              )}
            </div>
            <div className="flex justify-between space-y-1">
              <DialogClose>
                <button className="bg-green-500/70 hover:bg-green-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                  Cancel
                </button>
              </DialogClose>
              <DialogClose>
                <button
                  className="bg-red-500/70 hover:bg-red-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                  onClick={form.handleSubmit(submitProctorsNote)}
                >
                  Save
                </button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
