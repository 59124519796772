import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const FormatString = (str) => {
  return str
    .split("_") // Split string on underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(" "); // Join the words with spaces
};

export const convertTimeToIST = (time: string) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Asia/Kolkata",
    hour: "numeric",
    minute: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("en-IN", options);
  return formatter.format(new Date(time));
};

export const calculateReportingTime = (startTime: string) => {
  const startDateTime = new Date(startTime);

  // Subtract 45 minutes (45 * 60 * 1000 milliseconds) from the start time
  const reportingTime = new Date(startDateTime.getTime() - 45 * 60 * 1000);

  return convertTimeToIST(reportingTime.toISOString());
};

export const calculateLastEntryTime = (startTime: string) => {
  const startDateTime = new Date(startTime);

  // Subtract 10 minutes (10 * 60 * 1000 milliseconds) from the start time
  const reportingTime = new Date(startDateTime.getTime() - 10 * 60 * 1000);

  return convertTimeToIST(reportingTime.toISOString());
};
