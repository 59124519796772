import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";

interface LimitSelectorProps {
  pageSize: number;
  onValueChange: (value: string) => void;
}

export const LimitSelector = ({
  pageSize,
  onValueChange,
}: LimitSelectorProps) => {
  return (
    <Select value={String(pageSize)} onValueChange={onValueChange}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Limit" />
      </SelectTrigger>
      <SelectContent className="bg-white w-[200px]">
        <SelectGroup>
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="25">25</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
