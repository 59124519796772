import { Schedule } from "src/redux/types/admit-card";
import moment from "moment";
import { Radio } from "lucide-react";
import { TerminateCandidateDialog } from "./TerminateCandidateDialog";
import { SelectNoteDialog } from "./SelectNoteDialog";

interface TableRowProps {
  schedule: Schedule;
  examTermination: (id: number) => void;
}

export const ManageExamTableRow = ({
  schedule,
  examTermination,
}: TableRowProps) => {
  const { slot, ...keys } = schedule;

    const isExamMissed = (startTime: string, status: string): boolean => {

      const examTime = new Date(startTime);
      const now = new Date();

      // Calculate the time 10 minutes before the exam start time
      const latestEntryTime = new Date(examTime);
      latestEntryTime.setMinutes(examTime.getMinutes() - 10);

      const missedTime = new Date(examTime);
      missedTime.setMinutes(examTime.getMinutes() + 5);

      // Return true if the current time is after 10 minutes before the exam start time
      if(now > latestEntryTime && now > missedTime) {
        if (
          status.toLowerCase() !== "appearing" &&
          status.toLowerCase() !== "appeared" &&
          status.toLowerCase() !== "terminated"
        ) {
          return true;
        }
      }

      return false;
    };

  const isMissed = isExamMissed(schedule.slot.startTime, schedule.status);

  return (
    <tr>
      <td className="p-1">{schedule.candidateName}</td>
      <td className="p-1">{schedule.candidateEmail}</td>
      <td className="p-1">{schedule.phoneNumber}</td>
      <td className="p-1">{schedule.whatsAppNumber}</td>
      <td className="p-1">{schedule.admitCardNumber}</td>
      <td className="p-1">{schedule.postName}</td>
      <td className="p-1">{schedule.slot.state}</td>
      <td className="p-1">{moment(schedule.slot.date).format("DD-MM-YY")}</td>
      <td className="p-1">
        {moment(schedule.slot.startTime).format("hh:mm a")}
      </td>
      <td>
        {schedule.status === "APPEARED" || isMissed ? (
          <SelectNoteDialog scheduleId={schedule.id} status={schedule.status} isMissed={isMissed}>
            <button className="border border-black rounded-md bg-gray-200 px-3 py-1">
              Add Note
            </button>
          </SelectNoteDialog>
        ) : (
          ""
        )}
      </td>
      <td className={`${isMissed ? "text-red-500" : ""} p-1 font-medium`}>
        {isMissed ? (
          "MISSED"
        ) : schedule.status.toLowerCase() === "appearing" ? (
          <span className="text-amber-500 flex gap-1">
            <Radio />
            {schedule.status}
          </span>
        ) : schedule.status.toLowerCase() === "appeared" ? (
          <span className="text-green-500">{schedule.status}</span>
        ) : schedule.status.toLowerCase() === "terminated" ? (
          <span className="text-red-500">{schedule.status}</span>
        ) : (
          <span className="text-black">{schedule.status}</span>
        )}
      </td>
      <td className="p-1">
        <TerminateCandidateDialog
          examTermination={examTermination}
          scheduleId={schedule.id}

          name={schedule.candidateName}
        >
          <button className="bg-red-500/80 hover:bg-red-500/60 h-8 px-2 py-1 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-white hover:text-black font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
            Terminate
          </button>
        </TerminateCandidateDialog>
      </td>
    </tr>
  );
};
