import { EditAdmitCard } from "../types/edit-admit";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";


export const getSchedules = async (
  pageNumber: number,
  pageSize: number,
  query: string
) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/get-schedules?page=${pageNumber}&pagesize=${pageSize}&${query}`
    );

    return response.data;
  } catch (error) {
    console.error(error, "snap!...something is wrong!");
  }
};

export const deleteAdmitCard = async (id: number) => {
  try {
    const api = axiosAuth();
    const response = await api.delete(`${BASE_URL}/delete-admit/${id}`);

    return response.data;
  } catch (error) {
    console.log("snap! something went wrong!");
  }
};

export const editAdmitCard = async (id: number, values: EditAdmitCard) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(`${BASE_URL}/edit-admit/${id}`, {
      candidateName: values.candidateName,
      postName: values.postName,
    });

    return response.data;
  } catch (error) {
    console.error("snap! something went wrong!");
  }
};
