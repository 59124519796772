import { ArrowBack } from "@mui/icons-material";
import { useAppSelector } from "../../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { CLOUD_FRONT_URL } from "../../../redux/api/appConst";
import {
  EditEbookUser,
  UpdateEbookUserStatusApi,
} from "../../../redux/api/_ebook-user.api";
import { toast } from "react-toastify";
import { Button } from "../../../components/buttons/shadcn-button";
import { Input } from "src/components/input/input";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { EditEbookUserSchema } from "src/schemas/edit-ebook-user";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil, ReceiptText } from "lucide-react";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/form";
import { Post, Posts } from "src/utils/posts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { states } from "src/schemas/create-candidate";

type View = "details" | "edit";

export const BookUserDetail = () => {
  const { currentEbookUser } = useAppSelector((state) => state.userReducer);

  const [view, setView] = useState<View>("details");

  interface Entry {
    label: string;
    value: string;
  }

  interface DetailCardProps {
    title: string;
    entries: Entry[];
  }

  const onActivatedCandidates = async (status: "APPROVED" | "PENDING") => {
    try {
      const statusCode = await UpdateEbookUserStatusApi(
        [currentEbookUser.sl],
        status
      );
      if (statusCode === 200) {
        toast(
          `Successfully Changed the status of ${currentEbookUser.fullName} to ${status} !`,
          {
            type: "success",
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DetailCard = ({ title, entries }: DetailCardProps) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
        {entries.map((entry) => (
          <div key={entry.label}>
            <label className="block text-gray-700 font-medium mb-2">
              {entry.label}
            </label>
            <p className="text-gray-900">{entry.value}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const EditCard = ({
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div>{children}</div>
    </div>
  );

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const form = useForm<z.infer<typeof EditEbookUserSchema>>({
    resolver: zodResolver(EditEbookUserSchema),
    defaultValues: {
      fullName: currentEbookUser.fullName,
      email: currentEbookUser.emailId,
      phoneNumber: currentEbookUser.phoneNumber,
      address: currentEbookUser.address.address,
      state: currentEbookUser.address.State,
      post: currentEbookUser.jobPost as Post,
      postCode: currentEbookUser.address.PostCode,
    },
  });

  const onSubmit = (values: z.infer<typeof EditEbookUserSchema>) => {
    EditEbookUser(values, String(currentEbookUser.sl)).then((response) => {
      console.log(response);
      if (response.message === "success") {
        toast.success("Candidate details updated successfully");
      }
    });
  };

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto p-6">
        <div className="p-2 flex justify-between">
          <p className="text-2xl">
            {view === "details" ? "Candidate Details" : "Edit Candidate"}
          </p>
          <div className="join gap-3">
            <button
              className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
              onClick={() =>
                navigate(`/ebook-users?${searchParams.toString()}`)
              }
            >
              <ArrowBack />
              Back
            </button>
            <button
              className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
              onClick={() => {
                if (view === "details") {
                  setView("edit");
                  return;
                }
                setView("details");
              }}
            >
              {view === "edit" ? (
                <div className="flex gap-2 items-center">
                  Details <ReceiptText className="size-4" />
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  Edit <Pencil className="size-4" />
                </div>
              )}
            </button>
          </div>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-2">
              {view === "details" ? (
                <DetailCard
                  title="Personal Information"
                  entries={[
                    { label: "Full Name", value: currentEbookUser.fullName },
                    { label: "Email", value: currentEbookUser.emailId },
                    {
                      label: "Phone Number",
                      value: currentEbookUser.phoneNumber,
                    },
                  ]}
                />
              ) : (
                <EditCard title="Personal Information">
                  <div className="grid grid-cols-3 gap-3 w-full">
                    <FormField
                      control={form.control}
                      name="fullName"
                      render={({ field }) => (
                        <FormItem>
                          <label className="block text-gray-700 font-medium mb-2">
                            Full Name
                          </label>
                          <FormControl>
                            <Input placeholder="fullName" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <label className="block text-gray-700 font-medium mb-2">
                            Email
                          </label>
                          <FormControl>
                            <Input placeholder="Email" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="phoneNumber"
                      render={({ field }) => (
                        <FormItem>
                          <label className="block text-gray-700 font-medium mb-2">
                            Phone number
                          </label>
                          <FormControl>
                            <Input placeholder="Phone number" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </EditCard>
              )}
              {view === "details" ? (
                <DetailCard
                  title="Job Details"
                  entries={[
                    { label: "Job Post", value: currentEbookUser.jobPost },
                    { label: "SL", value: currentEbookUser.sl.toString() },
                  ]}
                />
              ) : (
                <EditCard title="Job Details">
                  <FormField
                    control={form.control}
                    name="post"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Candidate's post</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select Candidate's post" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {Posts.map((post) => {
                              return (
                                <SelectItem key={post} value={post}>
                                  {post}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </EditCard>
              )}
              {view === "details" ? (
                <DetailCard
                  title="Address Information"
                  entries={[
                    {
                      label: "PostCode",
                      value: currentEbookUser.address.PostCode,
                    },
                    { label: "State", value: currentEbookUser.address.State },
                    {
                      label: "Address",
                      value: currentEbookUser.address.address,
                    },
                  ]}
                />
              ) : (
                <EditCard title="Address Information">
                  <div className="grid w-full grid-cols-3 gap-3">
                    <FormField
                      control={form.control}
                      name="postCode"
                      render={({ field }) => (
                        <FormItem>
                          <label className="block text-gray-700 font-medium mb-2">
                            Post Code
                          </label>
                          <FormControl>
                            <Input placeholder="Post code" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="state"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select Candidate's post" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {states.map((post) => {
                                return (
                                  <SelectItem key={post} value={post}>
                                    {post}
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="address"
                      render={({ field }) => (
                        <FormItem>
                          <label className="block text-gray-700 font-medium mb-2">
                            Address
                          </label>
                          <FormControl>
                            <Input placeholder="Address" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </EditCard>
              )}
              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-bold mb-4">Payment Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                  {[
                    {
                      label: "Payment Amount",
                      value: String(currentEbookUser.paymentAmount),
                    },
                    {
                      label: "Payment Image",
                      value: Array.isArray(currentEbookUser.paymentInfo)
                        ? currentEbookUser.paymentInfo.map((info) =>
                            /\.(jpg|jpeg|png|gif)$/i.test(info.FileUrl) ? (
                              <img
                                key={info.TransactionId}
                                src={`${CLOUD_FRONT_URL}/${info.FileUrl}`}
                                alt="Payment Receipt"
                                style={{ maxWidth: "300px" }} // Set the maximum width for the image
                              />
                            ) : (
                              `FileUrl: ${info.FileUrl}, TransactionId: ${info.TransactionId}`
                            )
                          )
                        : "Unavailable",
                    },
                  ].map((entry) => (
                    <div key={entry.label}>
                      <label className="block text-gray-700 font-medium mb-2">
                        {entry.label}
                      </label>
                      <p className="text-gray-900">{entry.value}</p>
                    </div>
                  ))}
                </div>
              </div>

              <DetailCard
                title="Dates"
                entries={[
                  {
                    label: "Created Date",
                    value: currentEbookUser.createdDate,
                  },
                  {
                    label: "Updated Date",
                    value: currentEbookUser.updatedDate,
                  },
                ]}
              />
              <DetailCard
                title="Status"
                entries={[{ label: "Status", value: currentEbookUser.status }]}
              />
              <DetailCard
                title="Reading Token"
                entries={[
                  {
                    label: "Reading Token",
                    value: currentEbookUser.readingToken || "None",
                  },
                ]}
              />
            </div>
            <div className="join gap-3 py-4">
              <Button
                size="lg"
                className=""
                type="button"
                onClick={() => {
                  navigate(
                    `/ebook-users?=${new URLSearchParams(location.search)}`
                  );
                  onActivatedCandidates(
                    currentEbookUser.status === "PENDING"
                      ? "APPROVED"
                      : "PENDING"
                  );
                }}
              >
                {currentEbookUser.status === "PENDING"
                  ? "APPROVE"
                  : "DEACTIVATE"}
              </Button>
              <Button size="lg" type="submit">
                Save details
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};
