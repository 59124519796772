import { NavigateBack } from "../../../../components/ui/navigate-back";
import { CreateSlotDialog } from "./create-slot-dialog";

export const ManageSlotsCardHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Manage Slots</p>
      <div className="flex gap-3 items-center">
        <NavigateBack href="/exams" />
        <CreateSlotDialog>
          <button className="btn btn-xs sm:btn-sm md:btn-md join-item">
            Create Slot
          </button>
        </CreateSlotDialog>
      </div>
    </div>
  );
};
