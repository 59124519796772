import { NavigateBack } from "../../../../components/ui/navigate-back";
import { CreateQuestionDialog } from "./create-questions-dialog";

export const QuestionBankCardHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Manage Questions</p>
      <div className="flex items-center gap-3">
        <NavigateBack href="/exams" />
        <CreateQuestionDialog>
          <button className="btn btn-xs sm:btn-sm md:btn-md join-item">
            Create Questions
          </button>
        </CreateQuestionDialog>
      </div>
    </div>
  );
};
