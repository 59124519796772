import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { QuestionBankCardHeader } from "./question-bank-card-header";
import { QuestionBankTable } from "./question-bank-table";

export const QuestionBankListView = () => {
  return (
    <DashboardDefaultContainer>
      <QuestionBankCardHeader />
      <QuestionBankTable />
    </DashboardDefaultContainer>
  );
};
