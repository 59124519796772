import { useEffect, useState, useTransition } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/input/Select";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Posts } from "../../../../utils/posts";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/shadcn-button";
import { Loader2 } from "lucide-react";
import { CreateIndividualSlot } from "src/redux/api/_exams.api";
import { toast } from "react-toastify";
import { states } from "src/schemas/create-candidate";
import { CreateIndividualSlotSchema } from "src/schemas/create-individual-slot";

export const CreateIndividualSlotForm = () => {
  const form = useForm<z.infer<typeof CreateIndividualSlotSchema>>({
    resolver: zodResolver(CreateIndividualSlotSchema),
  });

  const [isPending, setIsPending] = useState<boolean>(false);

  const onSubmit = (values: z.infer<typeof CreateIndividualSlotSchema>) => {
    setIsPending(true);
    CreateIndividualSlot(values)
      .then((data) => {
        if (data.message.toLowerCase() === "success") {
          toast.success("Slot Created!");
        }
        const slotCreatedEvent = new Event("slotCreated");
        window.dispatchEvent(slotCreatedEvent);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset();
    }
  }, [form.reset, form.formState]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          control={form.control}
          name="email"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Candidate's Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Candidate's Email"
                  type="email"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="post"
          defaultValue={undefined}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Post</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select post" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Posts.map((post) => {
                    return (
                      <SelectItem key={post} value={post}>
                        {post}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="state"
          defaultValue={undefined}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select State" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {states.map((state) => {
                    return (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date"
          defaultValue=""
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Exam date</FormLabel>
              <FormControl>
                <Input {...field} placeholder="MM-DD-YYYY" type="date" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="startTime"
          defaultValue=""
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Start time</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="HH:MM in 24 hour format"
                  type="time"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="endTime"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>End time</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="HH:MM in 24 hour format"
                  type="time"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="slotName"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slot name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Slot Name" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="zoom_link"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Zoom Link</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Zoom Link" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending ? <Loader2 className="animate-spin mx-auto" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
