import { Posts } from "src/utils/posts";
import { StateList } from "src/utils/states";
import { z } from "zod";

const states = StateList.map((state) => state.state);

export const EditEbookUserSchema = z.object({
  fullName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().min(1).max(10),
  post: z.enum(Posts),
  postCode: z.string().min(1).max(6),
  state: z.enum(states as [string, ...string[]]),
  address: z.string().min(1),
});
