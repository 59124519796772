import { ArrowBack } from "@mui/icons-material";
import AdmitCardStructure from "./AdmitCardStructure";
import { useState } from "react";
import { Pencil, ReceiptText } from "lucide-react";
import { useAppSelector } from "src/redux/hooks";
import { useForm } from "react-hook-form";
import { EditAdmitCard } from "src/redux/types/edit-admit";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/form/form";
import { Input } from "src/components/input/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { states } from "src/schemas/create-candidate";
import { Posts } from "src/utils/posts";
import { Button } from "src/components/buttons/shadcn-button";
import { editAdmitCard } from "src/redux/api/schedules";
import { toast } from "react-toastify";

interface AdmitcardDetailProps {
  onClose: Function;
}

type View = "edit" | "admit";

const AdmitCardDetail = ({ onClose }: AdmitcardDetailProps) => {
  const { schedule } = useAppSelector((state) => state.userReducer);

  const [view, setView] = useState<View>("admit");

  const form = useForm<EditAdmitCard>({
    defaultValues: {
      candidateName: schedule.candidateName,
      postName: schedule.postName,
    },
  });

  const onSaveDetails = async (values: EditAdmitCard) => {
    const id = schedule.id;
    try {
      const response = await editAdmitCard(id, { ...values });
      if (response) {
        toast.success("Admit Card Updated!");
      }
    } catch (error) {
      toast.error("Couldn't update Admit Card.");
      console.error("Something went wrong!");
    }
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSaveDetails)}>
          <div className="p-2 flex justify-between">
            <p className="text-2xl">Admit Card</p>
            <div className="join gap-3">
              <button
                className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onClose()}
              >
                <ArrowBack />
                Back
              </button>
              <button
                className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => {
                  if (view === "admit") {
                    setView("edit");
                    return;
                  }
                  setView("admit");
                }}
              >
                {view === "edit" ? (
                  <div className="flex gap-2 items-center">
                    Admit <ReceiptText className="size-4" />
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    Edit <Pencil className="size-4" />
                  </div>
                )}
              </button>
            </div>
          </div>

          <div>
            {view === "admit" ? (
              <AdmitCardStructure schedule={schedule} />
            ) : (
              <div className="grid grid-cols-4 w-full gap-3 items-center">
                <FormField
                  control={form.control}
                  name="candidateName"
                  render={({ field }) => (
                    <FormItem>
                      <label className="block text-gray-700 font-medium mb-2">
                        Candidate's Name
                      </label>
                      <FormControl>
                        <Input placeholder="Full Name" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="postName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Candidate's post</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={schedule.postName}
                              {...field}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {Posts.map((post) => {
                            return (
                              <SelectItem key={post} value={post}>
                                {post}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <Button size="lg" type="submit">
                  Save
                </Button>
              </div>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};

export default AdmitCardDetail;
