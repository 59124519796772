import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "src/components/buttons/shadcn-button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/form";
import { Input } from "src/components/input/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { CreateAdmitSchema, statusEnums } from "src/schemas/create-admit";
import { states } from "src/schemas/create-candidate";
import { Posts } from "src/utils/posts";
import { z } from "zod";

const CreateAdmitForm = () => {
  const form = useForm<z.infer<typeof CreateAdmitSchema>>({
    resolver: zodResolver(CreateAdmitSchema),
  });

  const [isPending, setIsPending] = useState<boolean>(false);

  const onSubmit = (values: z.infer<typeof CreateAdmitSchema>) => {
    setIsPending(true);
    console.log(values);
    toast.success("Admit Created!");
    setIsPending(false);
  };

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset();
    }
  }, [form.reset, form.formState]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-1 mb-1">
          <FormField
            control={form.control}
            name="rollNo"
            defaultValue=""
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Roll Number</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Roll Number" type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="applicationId"
            defaultValue=""
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Application Id</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Application Id"
                    type="number"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-1 justify-between mb-1">
          <FormField
            control={form.control}
            name="candidateId"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate Id</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Candidate Id" type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Exam Status</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Exam Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {statusEnums.map((status) => {
                      return (
                        <SelectItem key={status} value={status}>
                          {status}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-1 justify-between mb-1">
          <FormField
            name="postName"
            defaultValue={undefined}
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Post</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select post" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Posts.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="state"
            defaultValue={undefined}
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select State" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {states.map((state) => {
                      return (
                        <SelectItem key={state} value={state}>
                          {state}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="maxCapacity"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Max capacity</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Max capacity" type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-1 justify-between mb-1">
          <FormField
            control={form.control}
            name="date"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Exam date</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="MM-DD-YYYY" type="date" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startTime"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start time</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="HH:MM in 24 hour format"
                    type="time"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endTime"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>End time</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="HH:MM in 24 hour format"
                    type="time"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="slotName"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slot name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Slot Name" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="zoom_link"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Zoom Link</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Zoom Link" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center justify-center my-3">
          <Button disabled={isPending} className="hover:bg-green-400">
            {isPending ? "Creating..." : "Create Admit"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CreateAdmitForm;
