import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/dialogue/radix-dialog";
import CreateAdmitForm from "./createAdmitForm";

interface CreateAdmitDialogProps {
  children: React.ReactNode;
}

const CreateAdmitDialog = ({ children }: CreateAdmitDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-slate-100">
        <DialogHeader>
          <DialogTitle>Create Admit</DialogTitle>
        </DialogHeader>
        <CreateAdmitForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateAdmitDialog;
