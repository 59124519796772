import { ArrowBack } from "@mui/icons-material";
import { FC } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { CLOUD_FRONT_URL } from "../../../redux/api/appConst";


interface ViewCandidateDetailsProps {
  onClose: Function;
}

interface Entry {
  label: string;
  value: string;
}

interface DetailCardProps {
  title: string;
  entries: Entry[];
}

const DetailCard = ({ title, entries }: DetailCardProps) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
      {entries.map((entry) => (
        <div key={entry.label}>
          <label className="block text-gray-700 font-medium mb-2">
            {entry.label}
          </label>
          <p className="text-gray-900">{entry.value}</p>
        </div>
      ))}
    </div>
  </div>
);

export const ViewCandidateDetails: FC<ViewCandidateDetailsProps> = ({ onClose }) => {
  const { currentCandidate } = useAppSelector((state) => state.userReducer);

  const personalInfo: Entry[] = [
    {
      label: "Candidate Name",
      value: currentCandidate.candidateName,
    },
    {
      label: "Whatsapp number",
      value: currentCandidate.whatsAppNo,
    },
    {
      label: "Email address",
      value: currentCandidate.emailAddress,
    },
    {
      label: "Calling number",
      value: currentCandidate.callingMobileNo,
    },
    {
      label: "Applied Post",
      value: currentCandidate.post,
    },
    {
      label: "Submission time",
      value: new Date(currentCandidate.timestamp).toUTCString(),
    },

    {
      label: "Age",
      value: currentCandidate.age,
    },
    {
      label: "Sex",
      value: currentCandidate.sex,
    },
    {
      label: "Religion",
      value: currentCandidate.religion,
    },
    {
      label: "Category",
      value: currentCandidate.category,
    },
    {
      label: "Nationality",
      value: currentCandidate.nationality,
    },
    {
      label: "Marital status",
      value: currentCandidate.maritalStatus,
    },
  ];

  const identificationInfo: Entry[] = [
    {
      label: "Candidate ID",
      value: String(currentCandidate.id),
    },
    {
      label: "Aadhar Card Image",
      value: currentCandidate.aadharCard,
    },
    {
      label: "Pan Card Image",
      value: currentCandidate.panCard,
    },
    {
      label: "Aadhar number",
      value: currentCandidate.adharNumber,
    },
  ];

  const addressInfo: Entry[] = [
    {
      label: "Address",
      value: currentCandidate.address,
    },
    {
      label: "Pincode",
      value: currentCandidate.pinCode,
    },
    {
      label: "Permanent address",
      value: currentCandidate.permanentAddress,
    },
    {
      label: "Base district",
      value: currentCandidate.baseDistrict,
    },
    {
      label: "State of birth",
      value: currentCandidate.stateOfBirth,
    },
  ];

  const educationalInfo: Entry[] = [
    {
      label: "Educational qualification",
      value: currentCandidate.educationalQualification,
    },
    {
      label:
        "Percentage of marks in class 10 and in class 12 or highest education",
      value:
        currentCandidate.percentageOfMarksInClass10InClass12HighestEducation,
    },
  ];

  const experienceInfo: Entry[] = [
    {
      label: "Write about your experience if any",
      value: currentCandidate.writeAboutYourExperienceIfAny,
    },
    {
      label: "Experience certificate",
      value: currentCandidate.experienceCertificate,
    },
  ];

  const documentationInfo: Entry[] = [
    {
      label: "Passport Sized Recent Photograph",
      value: currentCandidate.passportSizedRecentPhotograph,
    },
    {
      label: "Signature",
      value: currentCandidate.signature,
    },
    {
      label: "Handwriting Sample",
      value: currentCandidate.handWritingSample,
    },
    {
      label: "Class 10th Mark Sheet",
      value: currentCandidate.class10ThMarkSheet,
    },
    {
      label: "Intermediate Marks Sheet",
      value: currentCandidate.intermediateMarksSheet,
    },
    {
      label: "Bachelor's Degree Mark Sheet",
      value: currentCandidate.bachelorDegreeMarkSheet,
    },
    {
      label: "SC/ST/OBC/HST/PH Certificate",
      value: currentCandidate.scStObcHstPhCertificate,
    },
    {
      label: "Profile Picture",
      value: currentCandidate.profilePicture,
    },
  ];

  const miscellaneousInfo: Entry[] = [
    {
      label: "Declaration",
      value: currentCandidate.declaration,
    },
    {
      label: "Status",
      value: currentCandidate.status,
    },
  ];

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="p-2 flex justify-between">
        <p className="text-2xl">Candidate Details</p>
        <div className="join gap-3">
          <button
            className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
            onClick={() => onClose()}
          >
            <ArrowBack />
            Back
          </button>
        </div>
      </div>
      <div className="space-y-2">
        <DetailCard title="Personal Information" entries={personalInfo} />
        <DetailCard
          title="Identification Information"
          entries={identificationInfo}
        />
        <DetailCard title="Address Information" entries={addressInfo} />
        <DetailCard title="Educational Information" entries={educationalInfo} />
        <DetailCard title="Experience Information" entries={experienceInfo} />
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold mb-4">Payment Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
            {[
              {
                label: "Transaction  Number",
                value: String(currentCandidate.paymentTransactionNo),
              },
              {
                label: "Payment Image",
                value:
                  currentCandidate.successfulTransactionScreenshots.includes(
                    "drive.google.com"
                  ) ? (
                    <a href={currentCandidate.successfulTransactionScreenshots}>
                      {currentCandidate.successfulTransactionScreenshots}
                    </a>
                  ) : (
                    <img
                      src={`${CLOUD_FRONT_URL}/${currentCandidate.successfulTransactionScreenshots}`}
                      alt="Payment Receipt"
                      style={{ maxWidth: "300px" }} // Set the maximum width for the image
                    />
                  ),
              },
            ].map((entry) => (
              <div key={entry.label}>
                <label className="block text-gray-700 font-medium mb-2">
                  {entry.label}
                </label>
                <p className="text-gray-900">{entry.value}</p>
              </div>
            ))}
          </div>
        </div>
        <DetailCard
          title="Documentation Information"
          entries={documentationInfo}
        />
        <DetailCard title="Other Information" entries={miscellaneousInfo} />
      </div>
    </div>
  );
};
