import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { ManageSlotTable } from "./manage-slot-table";
import { ManageSlotsCardHeader } from "./manage-slots-card-header";

export const ManageSlotsListView = () => {
  return (
    <DashboardDefaultContainer>
      <ManageSlotsCardHeader />
      <ManageSlotTable />
    </DashboardDefaultContainer>
  );
};
