import { ArrowLeft } from "lucide-react";
import { CreateEbookUserForm } from "./create-ebook-user-form";

export const CreateEbookUser = () => {
  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-6">
        <div className="p-2 flex justify-between">
          <p className="text-2xl">Create Ebook User</p>
          <div className="join">
            <button className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item">
              <a href="/ebook-users" className="flex items-center gap-x-1">
                <ArrowLeft />
                Back
              </a>
            </button>
          </div>
        </div>
        <div>
          <CreateEbookUserForm />
        </div>
      </div>
    </div>
  );
};
