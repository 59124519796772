
import React from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogTrigger,
  DialogDescription,
} from "src/components/dialogue/radix-dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { proctorsNoteApi } from "src/redux/api/_exams.api";

type Reasons = {
  id: number;
  reason: string;
};

interface TerminatCandidateDialogProps {
  children: React.ReactNode;
  examTermination: (id: number) => void;
  scheduleId: number;
  name: string;
}

interface FormProps {
  reason: string;
}

export const TerminateCandidateDialog = ({
  children,
  examTermination,
  scheduleId,
  name,
}: TerminatCandidateDialogProps) => {
  const reasons: Reasons[] = [
    { id: 0, reason: "Unfair Practice" },
    { id: 1, reason: "Rule Violation" },
    { id: 2, reason: "Misbehaviour" },
    { id: 3, reason: "Created unhealthy exam Environment" },
    { id: 4, reason: "Not Seen in Zoom" },
  ];


  const form = useForm<FormProps>();


  const submitProctorsNote = async (values: FormProps) => {
    try {
      examTermination(scheduleId);
      await proctorsNoteApi(scheduleId, values.reason);
    } catch (error) {
      console.error(error);
    }

  };

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>
            Are you sure, want to{" "}
            <span className="text-red-500 font-medium">Terminate {name.toUpperCase()}</span>
          </DialogTitle>
          <DialogDescription className="font-semibold text-red-500">
            This action is irreversible.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="flex flex-col gap-y-2">
            <div>
              <FormField
                name="reason"
                defaultValue={undefined}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Proctor's Note</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Reason" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {reasons.map((reason) => {
                          return (
                            <SelectItem key={reason.id} value={reason.reason}>
                              {reason.reason}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-between space-y-1">
              <DialogClose>
                <button className="bg-green-500/70 hover:bg-green-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                  Cancel
                </button>
              </DialogClose>
              <DialogClose>
                <button
                  className="bg-red-500/70 hover:bg-red-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                  onClick={form.handleSubmit(submitProctorsNote)}
                >
                  Terminate
                </button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
