import { useEffect, useState } from "react";
import { LimitSelector } from "src/components/ui/limit-selector";
import { GenericPagination } from "src/components/ui/pagination";
import { getSchedules } from "src/redux/api/schedules";
import { Schedule } from "src/redux/types/admit-card";
import { Button } from "@mui/material";
import { TrashIcon } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateList } from "src/utils/states";
import { useDebounce } from "use-debounce";
import { ViewAdmitTableRow } from "./admit-card-table-row";
import { setCurrentSchedule } from "src/redux/reducers";
import { useDispatch } from "react-redux";
import { DashboardDefaultContainer } from "../dashboard-default-container";
import { ArrowBack } from "@mui/icons-material";


export const ViewAdmitCards = () => {
  const examStatus = [
    "SCHEDULED",
    "APPEARING",
    "APPEARED",
    "MISSED",
    "TERMINATED",
  ];
  type Status = (typeof examStatus)[number];

  const jobPosts = [
    "RWO",
    "ITWO",
    "HRWO-A",
    "HRWO-B",
    "HRWO-C",
    "HRWO-D",
    "AWO",
    "DWO",
  ];
  type Job = (typeof jobPosts)[number];

  const dispatch = useDispatch();

  const viewAdmit = (schedule: Schedule) => {
    dispatch(setCurrentSchedule(schedule));
  };  
  const navigate = useNavigate();

  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const state = searchParams.get("state") || "";
  const post = searchParams.get("post") || ("" as Job);
  const status = searchParams.get("status") || ("" as Status);
  const name = searchParams.get("name") || "";
  const email = searchParams.get("email") || "";
  const id = searchParams.get("id") || "";
  const pageNumber = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pagesize") || "25", 10);

  const fetchSchedules = async () => {
    let queryString = "";
    if (state !== "Select one" && state) {
      queryString = `${queryString}&state=${state}`;
    }
    if (name.trim() !== "") {
      queryString = `${queryString}&name=${name}`;
    }
    if (email.trim() !== "") {
      queryString = `${queryString}&email=${email}`;
    }
    if (post !== "") {
      queryString = `${queryString}&post=${post}`;
    }
    if (status !== "") {
      queryString = `${queryString}&status=${status}`;
    }
    if (id !== "") {
      queryString = `${queryString}&id=${id}`;
    }
    setLoading(true);
    try {
      const response = await getSchedules(pageNumber, pageSize, queryString);
      setSchedules(response.data.schedules);
      setError(null); // Clear previous errors
    } catch (error) {
      setError("Failed to fetch schedules.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [pageNumber, pageSize, debouncedSearchParams]);

  const goNext = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber + 1).toString(),
    });
    fetchSchedules();
  };

  const goPrev = () => {
    if (pageNumber === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber - 1).toString(),
    });
  };

  const jumpPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  const onLimitChange = (newLimit) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      pagesize: newLimit,
    });
  };

  const clearAllFilters = () => {
    setSearchParams({});
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <DashboardDefaultContainer>
      <div className="p-4 flex justify-between">
        <p className="text-2xl">View Admit Cards</p>
        <div className="join gap-3">
          <button
            className="rounded-lg bg-zinc-300 hover:bg-amber-200 text-xl font-semibold btn-xs sm:btn-sm md:btn-md join-item"
            onClick={() => navigate("/front-desk")}
          >
            <ArrowBack />
            Back
          </button>
        </div>
      </div>
      <div className="p-4 flex justify-between items-center">
        <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Email ID"
              value={email}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  email: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Name"
              value={name}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  name: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="number"
              className="grow"
              placeholder="Search by Slot ID"
              value={id}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  id: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <div className="flex flex-grow gap-3 items-end justify-end">
            <div className="mt-2">
              <select
                id="status"
                name="status"
                autoComplete="exam-status"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                value={status}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    status: e.target.value,
                    page: "1",
                  });
                }}
              >
                <option selected value="">
                  Select Exam Status
                </option>
                {examStatus.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <select
                id="state"
                name="state"
                autoComplete="state-name"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                value={state}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    state: e.target.value,
                    page: "1",
                  });
                }}
              >
                <option selected value="">
                  Select State
                </option>
                {StateList.map((item) => (
                  <option key={item.state} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between">
              <select
                value={post}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    post: e.target.value,
                    page: "1",
                  });
                }}
                className="border p-1 rounded-md h-9"
              >
                <option value="">All Posts</option>
                {jobPosts.map((post) => (
                  <option value={post.toLowerCase()}>{post}</option>
                ))}
              </select>
            </div>
            <Button onClick={clearAllFilters}>
              <TrashIcon className="size-5" />
            </Button>
          </div>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th className="p-1">Candidate Name</th>
            <th className="p-1">Candidate Email</th>
            <th className="p-1">Contact Number</th>
            <th className="p-1">Whatsapp Number</th>
            <th className="p-1">Admit Card Number</th>
            <th className="p-1">Post</th>
            <th className="p-1">State</th>
            <th className="p-1">Date</th>
            <th className="p-1">Start Time</th>
            <th className="p-1">Proctor's Note</th>
            <th className="p-1">Status</th>
            <th className="p-1">View Admit</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(schedules) &&
            schedules.map((schedule) => (
              <ViewAdmitTableRow
                key={schedule.id}
                schedule={schedule}
                viewAdmit={viewAdmit}
              />
            ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between py-5 px-2">
        <LimitSelector
          pageSize={pageSize}
          onValueChange={(newLimit) => onLimitChange(newLimit)}
        />
        <GenericPagination
          currentPage={pageNumber}
          onNext={goNext}
          onPrev={goPrev}
          jumpPage={jumpPage}
        />
      </div>
    </DashboardDefaultContainer>
  );
};
