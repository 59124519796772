import { useState, useTransition } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/form/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CreateMultipleQuestionsSchema } from "../../../../schemas/create-questions";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/shadcn-button";
import { Loader2 } from "lucide-react";
import { uploadQuestions } from "src/redux/api/_exams.api";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";

export const CreateMultipleQuestionsForm = () => {
  const form = useForm<z.infer<typeof CreateMultipleQuestionsSchema>>({
    resolver: zodResolver(CreateMultipleQuestionsSchema),
  });

  const [isPending, setIsPending] = useState<boolean>();

  const onSubmit = (values: z.infer<typeof CreateMultipleQuestionsSchema>) => {
    setIsPending(true);
    uploadQuestions(values)
      .then(({ status, message }) => {
        if (status === HttpStatusCode.Ok) {
          toast.success("Questions uploaded successfully");
        } else {
          toast.error(message);
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          control={form.control}
          name="questionsCsv"
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <FormItem>
              <FormLabel>Upload csv</FormLabel>
              <FormControl>
                <Input
                  {...fieldProps}
                  placeholder="csv"
                  type="file"
                  accept="text/*"
                  onChange={(event) =>
                    onChange(event.target.files && event.target.files[0])
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending ? <Loader2 className="animate-spin mx-auto" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
