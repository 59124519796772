import { ArrowBack } from "@mui/icons-material";
import { FC } from "react";
import { CreateCandidateForm } from "./create-candidate-form";

interface CreateCandidateProps {
  onClose: Function;
  onSubmit: Function;
}

export const CreateCandidate: FC<CreateCandidateProps> = ({
  onClose,
  onSubmit,
}) => {
  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto p-6">
        <div className="p-2 flex justify-between">
          <p className="text-2xl">Create Candidate</p>
          <div className="join">
            <button
              className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
              onClick={() => onClose()}
            >
              <ArrowBack />
              Back
            </button>
          </div>
        </div>
        <div>
          <CreateCandidateForm />
        </div>
      </div>
    </div>
  );
};
