import { z } from "zod";
import { StateList } from "../utils/states";
import { Posts } from "../utils/posts";

export const states = StateList.map((state) => state.state);
export const districts = StateList.flatMap((state) => state.districts);
export const getDistrictsByState = (state: string) =>
  StateList.find((entry) => entry.state === state)?.districts || [];
export const sex = ["Male", "Female"];
export const categories = ["General", "OBC", "SC", "ST", "HST", "PH"];
export const nationalites = ["Indian", "Other"];
export const maritalStatuses = ["Single", "Married", "Widow"];
export const qualitifications = [
  "12th Passed Arts",
  "12th Passed Commerce",
  "12th Passed Science",
  "Graduation in Arts",
  "Graduation in Commerce",
  "Graduation in Science",
  "Graduation in Management",
  "Graduation in IT or Computer Science",
  "Master Degree - Science",
  "Master Degree - Arts",
  "Master Degree -Commerce",
  "MBA",
  "MSW",
  "MCA",
  "Other Master Degree or Equivalent",
  "PhD",
];
export const statuesEnums = ["APPROVED", "PENDING"];
export const Religions = ["Hindu", "Christian", "Buddhism", "Muslim"];

const MB_BYTES = 1000000; // Number of bytes in a megabyte.

// This is the list of mime types you will accept with the schema
const ACCEPTED_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const CreateCandidateSchema = z.object({
  emailAddress: z.string().email(),
  baseDistrict: z.enum(districts as [string, ...string[]]).optional(),
  candidateName: z.string().min(3),
  adharNumber: z.string().optional(),
  fatherName: z.string().optional(),
  motherName: z.string().optional(),
  address: z.string().optional(),
  pinCode: z.string().max(6).optional(),
  permanentAddress: z.string().optional(),
  stateOfBirth: z.enum(states as [string, ...string[]]),
  dateOfBirth: z.string().optional(),
  age: z.string().max(100).optional(),
  sex: z.enum(sex as [string, ...string[]]).optional(),
  religion: z.enum(Religions as [string, ...string[]]).optional(),
  nationality: z.enum(nationalites as [string, ...string[]]).optional(),
  category: z.enum(categories as [string, ...string[]]).optional(),
  maritalStatus: z.enum(maritalStatuses as [string, ...string[]]).optional(),
  whatsAppNo: z.string().min(10).max(10),
  callingMobileNo: z.string().min(10).max(10),
  educationalQualification: z
    .enum(qualitifications as [string, ...string[]])
    .optional(),
  percentageOfMarksInClass10InClass12HighestEducation: z.string().optional(),
  writeAboutYourExperienceIfAny: z.string().optional(),
  declaration: z.string().optional(),
  paymentTransactionNo: z.string().optional(),
  successfulTransactionScreenshots: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  passportSizedRecentPhotograph: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  panCard: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  aadharCard: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  signature: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  handWritingSample: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  class10ThMarkSheet: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  intermediateMarksSheet: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  bachelorDegreeMarkSheet: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  experienceCertificate: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  scStObcHstPhCertificate: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  profilePicture: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", "
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
  status: z.enum(statuesEnums as [string, ...string[]]).optional(),
  post: z.enum(Posts),
});
