import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogTrigger,
  DialogDescription,
} from "../../../components/dialogue/radix-dialog";
import { Loader2 } from "lucide-react";

interface DeleteUserDialogProps {
  children: React.ReactNode;
  fullName: string;
  onClick: () => void;
  isPending: boolean;
}

export const DeleteUserDialog = ({
  children,
  fullName,
  onClick,
  isPending,
}: DeleteUserDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>
            Delete <span className="font-bold text-red-500">{fullName}?</span>
          </DialogTitle>
          <DialogDescription className="font-semibold">
            This action is irreversible.
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-between space-y-1">
          <DialogClose>
            <button className="bg-green-500/70 hover:bg-green-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
              Cancel
            </button>
          </DialogClose>
          <button
            className="bg-red-500/70 hover:bg-red-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
            onClick={onClick}
            disabled={isPending}
          >
            Confirm {isPending && <Loader2 className="ml-2" />}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
