import { z } from "zod";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";
import { CreateEbookUserSchema } from "src/schemas/create-ebook-user";
import axios from "axios";
import { EditEbookUserSchema } from "src/schemas/edit-ebook-user";

export const GetEbookUsersApi = async (
  query: string,
  page: number = 1,
  pageSize: number = 100
) => {
  const api = axiosAuth();
  const response = await api.get(
    `${BASE_URL}/get-bookusers?page=${page}&pagesize=${pageSize}&${query}`
  );
  return response.data.data.book_users;
};

export const UpdateEbookUserStatusApi = async (
  ids: Number[],
  status: "APPROVED" | "PENDING"
) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/update-booksuser-status`, {
    ids,
    status,
  });

  return response.status;
};

export const DeleteEbookUserBulkApi = async (ids: number[]) => {
  const api = axiosAuth();

  const response = await api.delete(`${BASE_URL}/delete-bookuser-bulk`, {
    data: {
      ids,
    },
  });

  return response;
};

export const DeleteEbookUserSingleApi = async (id: number) => {
  const api = axiosAuth();

  const response = await api.delete(`${BASE_URL}/delete-bookuser-single/${id}`);

  return response;
};

async function uploadFile(file: any) {
  if (!file) return;
  try {
    const api = axiosAuth();

    const { data } = await api.get(
      `${BASE_URL}/get-upload-url?file=${file.name}`
    );

    const { Key, url } = data.data;

    await axios.put(url, file);

    return Key;
  } catch (error) {
    console.error(error);
  }
}

export const SaveEbookUserSingleApi = async (
  values: z.infer<typeof CreateEbookUserSchema>
) => {
  try {
    const api = axiosAuth();

    const {
      fullName,
      email,
      post,
      whatsappNumber,
      completePostalAddress,
      state,
      pinCode,
      transactionNumber,
      transactionProof,
      status,
    } = values;

    const proofUrl = await uploadFile(transactionProof);
    const payload = {
      jobPost: post,
      emailId: email,
      fullName,
      address: {
        PostCode: pinCode,
        State: state,
        address: completePostalAddress,
      },
      phoneNumber: whatsappNumber,
      paymentInfo: [
        {
          FileUrl: proofUrl || "",
          TransactionId: transactionNumber || "",
        },
      ],
      status,
    };

    const response = await api.post(
      `${BASE_URL}/save-bookuser-single`,
      payload
    );
    console.log({ payload });

    return { status: response.status };
  } catch (error) {
    console.log(error);
    return { error: "Failed to create eBook user" };
  }
};

export const EditEbookUser = async (
  values: z.infer<typeof EditEbookUserSchema>,
  id: string
) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/booksuser/${id}`, {
    jobPost: values.post,

    emailId: values.email,

    fullName: values.fullName,

    phoneNumber: values.phoneNumber,

    address: {
      PostCode: values.postCode,
      State: values.state,
      address: values.address,
    },
  });

  return response.data;
};
