import React, { useEffect, useState } from "react";
import { getExamSubmission } from "src/redux/api/_exams.api"
import { CheckExamHeader } from "./CheckExamHeader";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setSubmissions } from "src/redux/reducers";
import moment from "moment";
import { Submission } from "src/redux/types/exam-submission";

interface CheckExamListViewProps {
  onClickCheck: (submission: Submission) => void;
}

const CheckExamListView = ({onClickCheck}: CheckExamListViewProps) => {

  const dispatch = useAppDispatch();
  const { submissions } = useAppSelector((state) => state.userReducer);
  const { results } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(true);

  const qualifiedAdmitArray: string[] = [];
  if(Array.isArray(results)){
    results.forEach((result) => {
    qualifiedAdmitArray.push(result.admitcard_number);
  });
  }

  const fetchExamSubmissions = async () => {
    setLoading(true);
    try {
      const response = await getExamSubmission();
      if (response?.message.toLowerCase() === "success") {
        dispatch(setSubmissions(response.data.exam_submissions));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=> {
    fetchExamSubmissions();
  }, [])

  const SubmissionTable = React.memo(() => {
    if(Array.isArray(submissions)) {
      return (
        <tbody>
          {
            submissions.map((submission) => {
              return (
                <tr>
                  <td
                    className={`${
                      qualifiedAdmitArray.includes(submission.admitCardNumber)
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {qualifiedAdmitArray.includes(submission.admitCardNumber)
                      ? "Checked"
                      : ""}
                  </td>
                  <td>{submission.admitCardNumber}</td>
                  <td>{submission.candidateName}</td>
                  <td>{submission.postName}</td>
                  <td>{submission.state}</td>
                  <td>{moment(submission.startTime).format("DD-MM-YYYY")}</td>
                  <td>{moment(submission.startTime).format("h:mm a")}</td>
                  <td>
                    <button
                      className="py-1 px-3 rounded-md border border-black bg-yellow-400 hover:bg-yellow-500/60 font-semibold"
                      onClick={() => onClickCheck(submission)}
                    >
                      Check Paper
                    </button>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      )
    }
    return null;
  })

  if(loading) {
    return <div>Loading...</div>
  }

  return (
    <>
    <CheckExamHeader/>
    <div>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Admit Card Number</th>
            <th>Name</th>
            <th>Post</th>
            <th>State</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>Check Paper</th>
          </tr>
        </thead>
        <SubmissionTable/>
      </table>
    </div>
    </>

  )
}

export default CheckExamListView

