import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../dialogue/radix-dialog";
import { Input } from "../input/input";
import { Button } from "../buttons/shadcn-button";

interface JumpToPageDialogProps {
  children: React.ReactNode;
  currentPageNumber: number;
  onJumpToPage: (page: number) => void;
}

export const JumpToPageDialog = ({
  children,
  currentPageNumber,
  onJumpToPage,
}: JumpToPageDialogProps) => {

  const [pageNumber, setPageNumber] = useState<number>(currentPageNumber);

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Jump to page</DialogTitle>
        </DialogHeader>
        <section className="flex flex-col gap-y-3 items-center justify-center">
          <Input
            inputMode="numeric"
            min={0}
            type="number"
            value={pageNumber}
            onChange={(e) => {
              setPageNumber(Number(e.target.value));
            }}
          />
          <Button
            onClick={() => onJumpToPage(pageNumber)}
            className="bg-green-500 text-white"
          >
            Jump to Page {pageNumber}
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
};
