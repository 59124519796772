import { Search } from "lucide-react";
import { JumpToPageDialog } from "../../../components/misc/jump-to-page-dialog";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../components/pagination/index";

interface CandidateListPaginationProps {
  currentPageNumber: number;
  onPrev: () => void;
  onNext: () => void;
  onJumpToPage: (page: number) => void;
}

export const CandidateListPagination = ({
  currentPageNumber,
  onPrev,
  onNext,
  onJumpToPage,
}: CandidateListPaginationProps) => {
  return (
    <Pagination>
      <PaginationContent className="join join-horizontal">
        <PaginationItem>
          <PaginationPrevious onClick={onPrev} className="cursor-pointer" />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink isActive>{currentPageNumber}</PaginationLink>
        </PaginationItem>
        <PaginationItem className="cursor-pointer">
          <JumpToPageDialog
            currentPageNumber={currentPageNumber}
            onJumpToPage={onJumpToPage}
          >
            <PaginationLink>
              <Search />
            </PaginationLink>
          </JumpToPageDialog>
        </PaginationItem>
        <PaginationItem>
          <PaginationNext onClick={onNext} className="cursor-pointer" />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
