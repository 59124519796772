import { Result } from "src/redux/types/exam-submission";
import { ManageResultHeader } from "./manage-results-header";
import { getResults } from "src/redux/api/_exams.api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { setResults } from "src/redux/reducers";

interface ResultListViewProps {
  onClickDetail: (submission: Result) => void;
}

const ResultListView = ({ onClickDetail }: ResultListViewProps) => {
  const dispatch = useDispatch();

  const { results } = useAppSelector((state) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchResults = async () => {
    setLoading(true);
    try {
      const response = await getResults(1, 10);

      if (response?.message.toLowerCase() === "success") {
        dispatch(setResults(response.data.qualified_candiates));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, []);

  const ResultTable = React.memo(() => {
    if (Array.isArray(results)) {
      return (
        <tbody>
          {results.map((result) => {
            return (
              <tr>
                <td>{result.sl}</td>
                <td>{result.candidate_name}</td>
                <td>{result.post}</td>
                <td>{result.state}</td>
                <td>{result.marks}</td>
                <td>{result.result}</td>
                <td>{result.admitcard_number}</td>
                <td>
                  <button
                    className="py-1 px-3 rounded-md border border-black bg-yellow-400 hover:bg-yellow-500/60 font-semibold"
                    onClick={() => onClickDetail(result)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return null;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ManageResultHeader />
      <div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Post</th>
              <th>State</th>
              <th>Marks</th>
              <th>Result</th>
              <th>Admit Card Number</th>
            </tr>
          </thead>
          <ResultTable />
        </table>
      </div>
    </>
  );
};

export default ResultListView;
