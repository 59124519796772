import { ArrowBack } from "@mui/icons-material";

interface NavigateBackProps {
  href: string;
}

export const NavigateBack = ({ href }: NavigateBackProps) => {
  return (
    <a
      className="hover:bg-zinc-100 p-2 cursor-pointer rounded-md flex items-center gap-3"
      href={href}
    >
      <ArrowBack />
      <span className="font-semibold">Back</span>
    </a>
  );
};
