import { useEffect, useState, useTransition } from "react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/input/Select";
import {
  CreateCandidateSchema,
  Religions,
  categories,
  districts,
  getDistrictsByState,
  maritalStatuses,
  nationalites,
  qualitifications,
  sex,
  states,
  statuesEnums,
} from "../../../schemas/create-candidate";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "../../../components/input/input";
import { Loader2 } from "lucide-react";
import { Button } from "../../../components/buttons/shadcn-button";
import { toast } from "react-toastify";
import { Posts } from "../../../utils/posts";
import { saveCandidateSingle } from "../../../redux/api/_candidates.api";
import { Checkbox, FormControlLabel } from "@mui/material";

const FormSectionHeader = ({ children }: { children: React.ReactNode }) => (
  <h2 className="text-2xl font-bold p-2 rounded-md border">{children}</h2>
);

export const CreateCandidateForm = () => {
  const form = useForm<z.infer<typeof CreateCandidateSchema>>({
    resolver: zodResolver(CreateCandidateSchema),
  });

  const [isPending, startTransition] = useTransition();

  const [selectedState, setSelectedState] = useState("");

  const [isChecked, setChecked] = useState(false);

  const onSubmit = (values: z.infer<typeof CreateCandidateSchema>) => {
    startTransition(() => {
      saveCandidateSingle(values).then(({ status, error }) => {
        if (status === 200) {
          toast.success("User created");
        } else if (error) {
          toast.error(error);
        }
      });
    });
  };

  const reSubmitCheck = () => {
    setChecked(!isChecked);
  };

  useEffect(() => {
    if (form.formState.isSubmitSuccessful && !isChecked) {
      form.reset();
    }
  }, [form.reset, form.formState, isChecked]);

  return (
    <Form {...form}>
      <FormControlLabel
        control={<Checkbox onChange={reSubmitCheck} checked={isChecked} />}
        label="Resubmit for the same Candidate ?"
      />
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormSectionHeader>Personal Information</FormSectionHeader>
        <div className="grid grid-cols-3 gap-3">
          <FormField
            control={form.control}
            name="candidateName"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Name</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's full name" {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="whatsAppNo"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Whatsapp Number</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's whatsapp number" {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="callingMobileNo"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Calling Number</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's calling number" {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="dateOfBirth"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Date of birth</FormLabel>
              <FormControl>
                <Input placeholder="11/02/2005" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-4 gap-3">
          <FormField
            control={form.control}
            name="emailAddress"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Email Address</FormLabel>
                <FormControl>
                  <Input placeholder="example@gmail.com" {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="post"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's post</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's post" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Posts.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="sex"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's sex</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's sex" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {sex.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="age"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's age</FormLabel>
                <FormControl>
                  <Input placeholder="21" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="fatherName"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Father's Name</FormLabel>
                <FormControl>
                  <Input placeholder="Father's name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="motherName"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Mother's Name</FormLabel>
                <FormControl>
                  <Input placeholder="Mother's name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-4 gap-3">
          <FormField
            control={form.control}
            name="religion"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's religion</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's religion" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Religions.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="category"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's category</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's category" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {categories.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="nationality"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's nationality</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's nationality" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {nationalites.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="maritalStatus"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's marital status</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's marital status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {maritalStatuses.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSectionHeader>Identification Information</FormSectionHeader>
        <div className="grid grid-cols-3 gap-3">
          <FormField
            control={form.control}
            name="aadharCard"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload Aadhar Card Image</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Aadhar Card Image"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="panCard"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload Pan Card Image</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Pan Card Image"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="adharNumber"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's Aadhar number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Candidate's aadhar card number"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSectionHeader>Address Information</FormSectionHeader>
        <FormField
          control={form.control}
          name="address"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Candidate's full address</FormLabel>
              <FormControl>
                <Input placeholder="Candidate's full address" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* <div className="flex items-center justify-start">
          <Input type="checkbox" className="w-fit size-5 mr-2" name="permanent-address-checkbox"/>
          <Label htmlFor="permenant-address-checkbox">Click here if permanent address same as above</Label>
        </div> */}
        <FormField
          control={form.control}
          name="permanentAddress"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Candidate's permanent address</FormLabel>
              <FormControl>
                <Input placeholder="Candidate's permanent address" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-3 gap-3">
          <FormField
            control={form.control}
            name="stateOfBirth"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's state</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    setSelectedState(value);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select state" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {states.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
                <FormDescription className="font-semibold text-red-400">
                  This is a required field
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="baseDistrict"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's district</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={
                          selectedState
                            ? "Select District"
                            : "Select a state first"
                        }
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {getDistrictsByState(selectedState).map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="pinCode"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Candidate's pin code</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's pin code" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSectionHeader>Educational Information</FormSectionHeader>
        <FormField
          control={form.control}
          name="educationalQualification"
          defaultValue={undefined}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Candidate's educational qualification</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Candidate's educational qualification" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {qualitifications.map((post) => {
                    return (
                      <SelectItem key={post} value={post}>
                        {post}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-3 gap-3">
          <FormField
            control={form.control}
            name="percentageOfMarksInClass10InClass12HighestEducation"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Percentage of marks in class 10 and in class 12 or highest
                  education
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Candidate's percentage of marks in class 10 and in class 12 or
                highest education"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="class10ThMarkSheet"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload class 10th marksheet</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="10th Marksheet"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="intermediateMarksSheet"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload intermediate marksheet</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Intermediate Marksheet"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSectionHeader>Experience Information</FormSectionHeader>
        <FormField
          control={form.control}
          name="writeAboutYourExperienceIfAny"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Experience information</FormLabel>
              <FormControl>
                <Input placeholder="About candidate's experience" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="experienceCertificate"
          defaultValue={undefined}
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <FormItem>
              <FormLabel>Upload experience certificate</FormLabel>
              <FormControl>
                <Input
                  {...fieldProps}
                  placeholder="Experience Certificate"
                  type="file"
                  accept="image/*"
                  onChange={(event) =>
                    onChange(event.target.files && event.target.files[0])
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormSectionHeader>Payment Information</FormSectionHeader>
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="paymentTransactionNo"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Payment Txn Number</FormLabel>
                <FormControl>
                  <Input placeholder="Txn Number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="experienceCertificate"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload Txn Screenshot</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Txn Screenshot"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSectionHeader>Documentation Information</FormSectionHeader>
        <div className="grid grid-cols-5 gap-3">
          <FormField
            control={form.control}
            name="passportSizedRecentPhotograph"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload passport sized photo</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Passport Sized Recent Photograph"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="signature"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload signature</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="signature"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="passportSizedRecentPhotograph"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload handwriting sample</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Handwriting sample"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="scStObcHstPhCertificate"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload caste certificate</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Caste certificate"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="profilePicture"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload profile picture</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Profile picture"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="declaration"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Declaration</FormLabel>
              <FormControl>
                <Input placeholder="Declaration" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-center w-full">
          <Button type="submit">
            {isPending ? (
              <Loader2 className="mx-auto animate-spin" />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
