import { Search } from "lucide-react";
import { JumpToPageDialog } from "src/components/misc/jump-to-page-dialog";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "src/components/pagination";

interface ManageQuestionsPaginationProps {
  onPrev: () => void;
  onNext: () => void;
  onJump: (page: number) => void;
  currentPage: number;
}

export const ManageQuestionsPagination = ({
  onPrev,
  onNext,
  onJump,
  currentPage,
}: ManageQuestionsPaginationProps) => {
  return (
    <Pagination>
      <PaginationContent className="join join-horizontal">
        <PaginationItem>
          <PaginationPrevious
            onClick={onPrev}
            className="cursor-pointer"
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink isActive>{currentPage}</PaginationLink>
        </PaginationItem>
        <PaginationItem className="cursor-pointer">
          <JumpToPageDialog
            currentPageNumber={currentPage}
            onJumpToPage={onJump}
          >
            <PaginationLink>
              <Search />
            </PaginationLink>
          </JumpToPageDialog>
        </PaginationItem>
        <PaginationItem>
          <PaginationNext
            onClick={onNext}
            className="cursor-pointer"
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
