import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";

export const SendEmails = async (
  emails: string[],
  subject: string,
  emailType: string,
  post?: string,
  message?: string
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${BASE_URL}/send-email-notification`, {
      emails,
      subject,
      message,
      post,
      email_type: emailType,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occurred",
    };
  }
};
