import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TableRow from "./TableRow";
import {
  DeleteSlot,
  GetSlots,
  markSlotasDone,
  PublishSlot,
} from "src/redux/api/_exams.api";
import { ManageSlotsPagination } from "./pagination";
import { LimitSelector } from "./manage-slots-limit-select";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setSlots } from "src/redux/reducers";
import { StateList } from "src/utils/states";
import { Button } from "@mui/material";
import { TrashIcon } from "lucide-react";
import { useDebounce } from "use-debounce";

export const ManageSlotTable: React.FC = () => {
  const jobPosts = [
    "RWO",
    "ITWO",
    "HRWO-A",
    "HRWO-B",
    "HRWO-C",
    "HRWO-D",
    "AWO",
    "DWO",
  ];
  type Job = (typeof jobPosts)[number];

  const dispatch = useAppDispatch();
  const { slots } = useAppSelector((state) => state.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageNumber = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
  const state = searchParams.get("state") || "";
  const post = searchParams.get("post") || ("" as Job);
  const [debouncedSearchParams] = useDebounce(searchParams, 1000);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const clearAllFilters = () => {
    setSearchParams({});
  };

  const fetchSlots = async () => {
    let queryString = "";

    if (state !== "Select one" && state) {
      queryString = `${queryString}&state=${state}`;
    }
    if (post !== "") {
      queryString = `${queryString}&post=${post}`;
    }
    try {
      const response = await GetSlots(pageNumber, pageSize, queryString);
      const slots = response.map((slot: any) => ({
        id: Number(slot.id),
        date: slot.date,
        post: slot.catagory,
        state: slot.state,
        zoom_link: slot.zoom_link,
        slotName: slot.slotName,
        maxCapacity: slot.maxCapacity,
        startTime: slot.startTime,
        endtime: slot.endtime,
        isEnabled: slot.enable,
        isMarked: slot.complete,
      }));
      dispatch(setSlots(slots));
    } catch (error) {
      toast.error("Failed to fetch the slots, try again!")
    }
  };

  useEffect(() => {
    const handleSlotCreated = () => fetchSlots();
    
    fetchSlots();
    window.addEventListener("slotCreated", handleSlotCreated);
    return () => {
      window.removeEventListener("slotCreated", handleSlotCreated);
    };
  }, [debouncedSearchParams]);

  const deleteSlotById = async (id: number) => {
    try {
      const response = await DeleteSlot(id);
      if (response.message.toLowerCase() === "success") {
        toast.success("Slot Deleted Successfully");
        fetchSlots();
      }
    } catch (error) {
      toast.error("Failed to Delete Slot.");
      console.error(error);
    }
  };

  const releaseSlot = (id: number) => {
    try {
      PublishSlot(Number(id)).then((response) => {
        if (response.message.toLowerCase() === "success") {
          toast.success("Slot Released");
        }
        fetchSlots();
      });
    } catch (error) {
      toast.error("Something went wrong !");
      console.error(`Failed to publish slot ${id}`, error);
    }
  };

  const handleSelect = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const markasDone = async () => {
    try {
      if(selectedRows.length > 0){
        const response = await markSlotasDone(selectedRows);
      if (response.message.toLowerCase() === "success") {
        toast.success(response.data.message);
        setSelectedRows([]);
        fetchSlots();
      }
      } else{
        toast.warning("Select a slot to Mark as Done");
      }
      
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

    const goNext = () => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: (pageNumber + 1).toString(),
      });
      fetchSlots();
    };

    const goPrev = () => {
      if (pageNumber === 1) {
        return;
      }
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: (pageNumber - 1).toString(),
      });
    };

    const jumpPage = (page: number) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: page.toString(),
      });
    };

    const onLimitChange = (newLimit) => {
      console.log(newLimit);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        pageSize: newLimit,
      })
    }

  return (
    <div>
      <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
        <div className="flex flex-grow gap-3 items-end justify-end">
          <div className="mt-2">
            <select
              id="state"
              name="state"
              autoComplete="state-name"
              className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
              value={state}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  state: e.target.value,
                  page: "1",
                });
              }}
            >
              <option selected value="">
                Select State
              </option>
              {StateList.map((item) => (
                <option key={item.state} value={item.state}>
                  {item.state}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between">
            <select
              value={post}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  post: e.target.value,
                  page: "1",
                });
              }}
              className="border p-1 rounded-md h-9"
            >
              <option value="">All Posts</option>
              {jobPosts.map((post) => (
                <option value={post.toLowerCase()}>{post}</option>
              ))}
            </select>
          </div>
          <Button onClick={clearAllFilters}>
            <TrashIcon className="size-5" />
          </Button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr className="text-base divide-x">
            <th>Mark as Done</th>
            <th>ID</th>
            <th>Date</th>
            <th>Post</th>
            <th>State</th>
            <th>Slot Name</th>
            <th>Max Capacity</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Enabled</th>
            <th>Edit</th>
            <th>Release Slot</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(slots) &&
            slots.map((row) => (
              <TableRow
                key={row.id}
                id={row.id}
                date={row.date}
                post={row.post}
                state={row.state}
                slotName={row.slotName}
                zoom_link={row.zoom_link}
                maxCapacity={row.maxCapacity}
                startTime={row.startTime}
                endtime={row.endtime}
                isEnabled={row.isEnabled}
                isMarked={row.isMarked}
                selected={selectedRows.includes(row.id)}
                onSelect={handleSelect}
                onClick={() => deleteSlotById(Number(row.id))}
                onRelease={() => releaseSlot(Number(row.id))}
              />
            ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between py-5 px-2">
        <LimitSelector
          pageSize={pageSize}
          onValueChange={(newLimit) => onLimitChange(newLimit)}
        />
        <ManageSlotsPagination
          currentPage={pageNumber}
          onNext={goNext}
          onPrev={goPrev}
          jumpPage={jumpPage}
        />
        <button
          className="px-3 py-2 w-48 rounded-md border border-green-400 hover:bg-accent bg-green-200"
          onClick={markasDone}
        >
          Mark as Done
        </button>
      </div>
    </div>
  );
};

export default ManageSlotTable;
