import React from "react";

interface DashboardDefaultContainerProps {
  children: React.ReactNode;
}

export const DashboardDefaultContainer = ({
  children,
}: DashboardDefaultContainerProps) => {
  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-3">
        {children}
      </div>
    </div>
  );
};
