import axios from "axios";
import { BASE_URL, CLOUD_FRONT_URL } from "./appConst";
import { axiosAuth } from "./common";

// Move to user api
export const GetLeads = async () => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/leads?offset=0&limit=10`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetProjects = async () => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/projects?offset=0&limit=100`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetProject = async (projectId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const UpdateRoofCondition = async (
  leadId: string,
  roof_condition: string
) => {
  try {
    const response = await axios.put(`${BASE_URL}/leads/${leadId}`, {
      roof_condition,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const UpdateDamage = async (
  leadId: string,
  date_of_damaged: string,
  type_of_damaged: string
) => {
  try {
    const response = await axios.put(`${BASE_URL}/leads/${leadId}`, {
      date_of_damaged,
      type_of_damaged,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetUploadUrl = async (file_name: string, projectId: number) => {
  try {
    const auth = axiosAuth();
    const response = await auth.post(`${BASE_URL}/upload/url`, {
      file_name,
      project_id: projectId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetUploadImgUrl = async (file_name: string) => {
  try {
    const auth = axiosAuth();
    const response = await auth.post(`${BASE_URL}/upload/img/url`, {
      file_name,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const DeleteDoc = async (docId: number) => {
  try {
    const auth = axiosAuth();
    const response = await auth.delete(`${BASE_URL}/upload/${docId}`);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const UpdateDocs = async (
  fileUrl: string,
  projectId: number,
  fileType: string,
  metadata = ""
) => {
  try {
    const auth = axiosAuth();
    const response = await auth.post(`${BASE_URL}/upload/update`, {
      file_url: `${CLOUD_FRONT_URL}/${fileUrl}`,
      project_id: projectId,
      file_type: fileType,
      metadata,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GenerateCertificate = async (id: number) => {
  try {
    const auth = axiosAuth();
    const response = await auth.get(`${BASE_URL}/gen/coc/${id}`);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const RemoveProject = async (id: number) => {
  try {
    const auth = axiosAuth();
    const response = await auth.delete(`${BASE_URL}/project/${id}`);
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const EditProgressSteps = async (
  id: number,
  step: string,
  status: "forward" | "reverse"
) => {
  try {
    const auth = axiosAuth();
    const response = await auth.patch(`${BASE_URL}/reset/${id}`, {
      step,
      status,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};
