import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { AppCSS } from "..";
import { CloseButton, TapButton } from "../buttons";
import { Lbl } from "../labels";
import { Spacer } from "../misc";
import { RowDiv } from "../misc/misc.styled";

interface DialogProps {
  open: boolean;
  onClose: Function;
  onConfirm: Function;
  message: string;
}

export const Prompt: React.FC<DialogProps> = ({
  open,
  onClose,
  message,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      <DialogTitle id="alert-dialog-title">Please Confirm!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>No</Button>
        <Button onClick={() => onConfirm()} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface AlertProps {
  open: boolean;
  onConfirm: Function;
  onClose?: Function;
  title: string;
  message: string;
  okayTitle?: string;
}

export const AppAlert: React.FC<AlertProps> = ({
  open,
  title,
  onConfirm,
  message,
  onClose,
  okayTitle,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RowDiv
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {onClose && (
            <TapButton
              width={120}
              height={38}
              color={AppCSS.RED}
              bgColor="none"
              title="Cancel"
              onTap={() => onClose()}
            />
          )}
          <TapButton
            width={120}
            height={38}
            title={okayTitle ? okayTitle : "Okay"}
            onTap={() => onConfirm()}
            bgColor={AppCSS.GREEN}
          />
        </RowDiv>
      </DialogActions>
    </Dialog>
  );
};

interface CustomAlertProps {
  open: boolean;
  onConfirm?: unknown;
  onClose?: any;
  onCancel?: any;
  title?: string;
  Content?: ReactNode;
  width?: number;
  height?: number;
}

export const CustomAlert: React.FC<CustomAlertProps> = ({
  title,
  open,
  onConfirm,
  onClose,
  Content,
  onCancel,
  width,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      aria-disabled={true}
    >
      {onCancel && (
        <RowDiv
          style={{
            justifyContent: "flex-end",
            width: "96%",
            marginTop: "10px",
          }}
        >
          <CloseButton onTap={() => (onCancel ? onCancel() : {})} />
        </RowDiv>
      )}
      {title && (
        <RowDiv
          style={{
            flexDirection: "row",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Lbl title={title} size={22} align="center" bold={600} />
        </RowDiv>
      )}

      <DialogContent
        sx={{
          justifyContent: "center",
          padding: "20px",
          // width: width ? `${width}px` : `380px`,
        }}
      >
        {Content && Content}
      </DialogContent>
      {onConfirm && (
        <DialogActions>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {onClose && onClose}
            {onConfirm}
          </RowDiv>
        </DialogActions>
      )}
      <Spacer direction="col" size={2} />
    </Dialog>
  );
};

export const AlertWithContent: React.FC<CustomAlertProps> = ({
  title,
  open,
  onConfirm,
  onClose,
  Content,
  width,
  height,
}) => {
  return (
    <Dialog
      open={open}
      aria-disabled={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "800px",
          height: "auto",
        },
      }}
    >
      {title && (
        <DialogTitle
          style={{
            justifyContent: "center",
          }}
          id="alert-dialog-title"
        >
          <Lbl title={title} size={22} align="center" bold={600} />
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          justifyContent: "center",
          padding: "20px",
          // width: width ? `${width}px` : `380px`,
        }}
      >
        {Content && Content}
      </DialogContent>
      {/* {onConfirm && (
        <DialogActions>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {onClose && onClose}
            {onConfirm}
          </RowDiv>
        </DialogActions>
      )} */}
      <Spacer direction="col" size={2} />
    </Dialog>
  );
};
