import { FC, useEffect, useState } from "react";
import { StateList } from "../../../utils/states";
import {
  GetCandidatesApi,
} from "../../../redux/api/_candidates.api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setCandidates } from "../../../redux/reducers";
import { Candidate } from "../../../redux/types/_candidate.type";
import { toast } from "react-toastify";
import Button from "@mui/material/Button/Button";
import { useDebounce } from "use-debounce";
import { TrashIcon } from "lucide-react";
import { cn } from "src/utils";
import { CandidateListPagination } from "../ManageCandidates/candidate-list-pagination";


interface ViewCandidatesListProps {
  onTapDetails: Function;
}

export const ViewCandidatesList: FC<ViewCandidatesListProps> = ({
  onTapDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { candidates } = useAppSelector((state) => state.userReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const jobPosts = [
    "RWO",
    "ITWO",
    "HRWO-A",
    "HRWO-B",
    "HRWO-C",
    "HRWO-D",
    "AWO",
    "DWO",
  ];
  type Job = (typeof jobPosts)[number];

  const state = searchParams.get("state") || "";
  const fromDate = searchParams.get("fromDate") || "";
  const toDate = searchParams.get("toDate") || "";
  const email = searchParams.get("email") || "";
  const name = searchParams.get("name") || "";
  const post = searchParams.get("post") || ("" as Job);
  const pageSize = parseInt(searchParams.get("pageSize") || "25", 10);
  const currentPageNumber = parseInt(searchParams.get("page") || "1", 10);

  const [selectedIds, setSelectIds] = useState<number[]>([]);
  const [failSafePageNumber, setFailSafePageNumber] = useState<number>();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  useEffect(() => {
    onFetchCandidate();
  }, [debouncedSearchParams]);

  const handleNextPage = () => {
    setFailSafePageNumber(currentPageNumber);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber + 1).toString(),
    });
    onFetchCandidate();
  };

  const handlePrevPage = () => {
    if (currentPageNumber === 1) {
      toast.error("This is the first page");
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber - 1).toString(),
    });
  };

  const handleJumpToPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  const onCopyEmails = async () => {
    const values = selectedIds.values();
    if (values) {
      const candidateList = Array.from(values);
      let emailText = "";
      candidateList.forEach((candidate) => {
        const result = candidates.find((item) => item.id === candidate);
        if (result) {
          if (emailText.length > 0) {
            emailText = `${emailText},${result.emailAddress}`;
          } else {
            emailText = result.emailAddress;
          }
        }
      });
      if (emailText.length > 0) {
        await navigator.clipboard.writeText(emailText);
        toast(`Successfully Copied to Clipboard!`, {
          type: "success",
        });
      } else {
        toast(`Please select items to Copy to Clipboard!`, {
          type: "error",
        });
      }
    }
  };

  const onFetchCandidate = async () => {
    let queryString = "";
    if (state !== "Select one" && state) {
      queryString = `${queryString}&state=${state}`;
    }
    if (name.trim() !== "") {
      queryString = `${queryString}&name=${name}`;
    }
    if (email.trim() !== "") {
      queryString = `${queryString}&email=${email}`;
    }
    if (post !== "") {
      queryString = `${queryString}&post=${post}`;
    }
    if (fromDate && toDate) {
      queryString = `${queryString}&startdate=${fromDate}&enddate=${toDate}`;
    }
    try {
      const data = await GetCandidatesApi(
        queryString,
        currentPageNumber,
        pageSize
      );
      if (data.data.candidates.length === 0) {
        if (failSafePageNumber) {
          setSearchParams({
            ...Object.fromEntries(searchParams),
            page: failSafePageNumber.toString(),
          });
        }
        toast.error("No more candidates");
        return;
      }
      if (data.data) {
        dispatch(setCandidates(data.data.candidates as Candidate[]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayCandidates = () => {
    if (Array.isArray(candidates)) {
      return candidates.map((candidate) => (
        <tr>
          <th>
            <label>
              <input
                type="checkbox"
                className="checkbox"
                value={candidate.id}
                checked={selectedIds.includes(candidate.id)}
                onChange={() => {
                  if (selectedIds.includes(candidate.id)) {
                    setSelectIds(
                      selectedIds.filter((id) => id !== candidate.id)
                    );
                  } else {
                    setSelectIds([...selectedIds, candidate.id]);
                  }
                }}
              />
            </label>
          </th>
          <td>
            <div className="flex items-center gap-3">
              <div>
                <div className="font-bold">{candidate.candidateName}</div>
                <div className="text-sm opacity-50">
                  {candidate.emailAddress}
                </div>
              </div>
            </div>
          </td>
          <td>{candidate.callingMobileNo}</td>
          <td>{candidate.whatsAppNo}</td>
          <td>
            {candidate.address}
            <br />
            <span className="badge badge-ghost badge-sm">
              {candidate.stateOfBirth}
            </span>
          </td>
          <td>{candidate.post}</td>
          <th>
            <button
              // onClick={() => onTapDetails()}
              className="btn btn-ghost btn-xs"
            >
              TXN details
            </button>
          </th>
          <th>
            <button
              className={cn(
                "font-bold text-center px-3 py-2 rounded-md text-white w-[100px]",
                candidate.status === "PENDING"
                  ? "bg-red-500 hover:bg-red-500/90"
                  : "bg-green-500 hover:bg-green-500/90"
              )}
            >
              {candidate.status}
            </button>
          </th>
          <th>
            <button
              className="btn btn-sm"
              onClick={() =>
                navigate(
                  `/send-email?email=${encodeURIComponent(
                    candidate.emailAddress
                  )}`
                )
              }
            >
              Notification
            </button>
          </th>
          <th>
            <button
              onClick={() => onTapDetails(candidate)}
              className="btn btn-sm"
            >
              Details
            </button>
          </th>
        </tr>
      ));
    }

    return <></>;
  };

  useEffect(() => {
    onFetchCandidate();
    if (state !== "Select one") {
      if (Array.isArray(candidates)) {
        const filteredList = candidates.filter(
          (candidate) => candidate.stateOfBirth === state
        );
        dispatch(setCandidates(filteredList));
      }
    }
  }, [state]);

  function clearAllFilters(): void {
    setSearchParams({});
  }

  return (
    <div>
      <div className="p-4 flex justify-between items-center">
        <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Email ID"
              value={email}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  email: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Name"
              value={name}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  name: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <div className="flex flex-grow gap-3 items-end justify-end">
            <div className="mt-2">
              <select
                id="state"
                name="state"
                autoComplete="state-name"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                value={state}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    state: e.target.value,
                    page: "1",
                  });
                }}
              >
                <option selected value="">
                  Select state
                </option>
                {StateList.map((item) => (
                  <option key={item.state} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </select>
              {/* <p style={{ width: 280 }} className="text-sm font-bold mt-4">
                {headOfficePrompt}
              </p> */}
            </div>
            <div className="flex justify-between">
              <select
                value={post}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    post: e.target.value,
                    page: "1",
                  });
                }}
                className="border p-1 rounded-md h-9"
              >
                <option value="">All posts</option>
                {jobPosts.map((post) => (
                  <option value={post.toLowerCase()}>{post}</option>
                ))}
              </select>
            </div>
            <Button onClick={clearAllFilters}>
              <TrashIcon className="size-5" />
            </Button>
          </div>
        </div>
      </div>

      <table className="table">
        {/* head */}
        <thead>
          <tr>
            <th>
              <label>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={() => {
                    if (selectedIds.length === candidates.length) {
                      setSelectIds([]);
                    } else {
                      setSelectIds(candidates.map((candidate) => candidate.id));
                    }
                  }}
                />
              </label>
            </th>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>WhatsApp Number</th>
            <th>Address</th>
            <th>Applied Post</th>
            <th>Transaction</th>
            <th>Status</th>
            <th>Notification</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {/* row 1 */}
          {displayCandidates()}
        </tbody>
      </table>
      <div className="flex justify-between items-center p-3 border rounded-md rounded-t-none">
        <div className="flex items-center justify-between px-3 py-2 border rounded-md">
          <label className="font-semibold">Limit</label>
          <select
            value={pageSize}
            onChange={(e) => {
              setSearchParams({
                ...Object.fromEntries(searchParams),
                pageSize: e.target.value,
              });
            }}
            className="border p-1 rounded-md ml-2"
          >
            {[10, 25].map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="flex-grow">
          <CandidateListPagination
            currentPageNumber={currentPageNumber}
            onNext={handleNextPage}
            onPrev={handlePrevPage}
            onJumpToPage={handleJumpToPage}
          />
        </div>
        <div className="join">
          <button
            className="btn btn-xs sm:btn-sm md:btn-md join-item"
            onClick={() => onCopyEmails()}
          >
            Copy Emails
          </button>
        </div>
      </div>
    </div>
  );
};
