import logo from "../../../../nhwm.png";
import sig from "../../../../mdsig.svg";
import moment from "moment";
import { Schedule } from "src/redux/types/admit-card";
import { CLOUD_FRONT_URL } from "src/redux/api/appConst";
import { calculateLastEntryTime, calculateReportingTime } from "src/utils";

interface AdmitCardStructureProps {
  schedule: Schedule;
}

const AdmitCardStructure = ({ schedule }: AdmitCardStructureProps) => {
  return (
    <div>
      <div className="max-w-5xl mx-auto p-6 bg-white shadow-lg">
        <header className="flex items-center border-b pb-4 mb-4">
          <div className="flex">
            <img src={logo} alt="Logo" className="w-24 mx-3" />
            <div className="flex flex-col justify-center">
              <p className="text-blue-600 font-semibold text-lg">
                National Healthcare
                <sup className="text-blue-600 text-xl">®️</sup>
              </p>
              <p className="text-blue-600 font-semibold text-lg">
                & Wellness Mission
              </p>
            </div>
          </div>
          <div className="flex-1">
            <div className="w-fit text-center ml-12">
              <h1 className="text-xl font-bold">
                BASIC RECRUITMENT TEST - 2024
              </h1>
              <h3 className="text-lg font-semibold">NHWM</h3>
              <p className="text-sm">
                A Collaborative Initiative of
                <br />
                Optim Health and Wellness (OPC) Pvt. Ltd.
              </p>
              <h2 className="text-lg font-medium">ADMIT CARD</h2>
            </div>
          </div>
        </header>
        <div className="grid grid-cols-2 border-2 border-gray-800 mb-4 p-3">
          <div className="flex">
            <div>
              <p>
                <strong>Candidate's Name:</strong>
              </p>
              <p className="mt-3">
                <strong>Applied Post:</strong>
              </p>
              <p>
                <strong>State:</strong>
              </p>
            </div>
            <div className="pl-3">
              <p className="font-semibold uppercase">
                {schedule.candidateName}
              </p>
              <p className="font-semibold mt-3">{schedule.postName}</p>
              <p>{schedule.slot.state}</p>
            </div>
          </div>
          <div className="flex justify-end">
            <div>
              <p>
                <strong>Roll Number:</strong>
              </p>
              <p>
                <strong>Admit Card Number:</strong>
              </p>
            </div>
            <div className="pl-3">
              <p className="font-medium">{schedule.rollNo}</p>
              <p className="font-medium">{schedule.admitCardNumber}</p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-center my-2 font-medium">Test Details</h3>
          <div className="flex border border-black">
            <div className="grow">
              <table className="w-full border-collapse border mb-4">
                <tbody>
                  <tr>
                    <th className="border px-2 py-1">Date of Test</th>
                    <td className="border px-2 py-1">
                      {moment(schedule.slot.date).format("MMM Do YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1">Slot Selected</th>
                    <td className="border px-2 py-1">
                      {moment(schedule.slot.startTime).format("hh:mm a")} -{" "}
                      {moment(schedule.slot.endtime).format("hh:mm a")}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1">Timing of Test</th>
                    <td className="border px-2 py-1">1 hour 30 minutes</td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1">Reporting Time</th>
                    <td className="border px-2 py-1">
                      {calculateReportingTime(schedule.slot.startTime)}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1">Last Entry Time</th>
                    <td className="border px-2 py-1">
                      {calculateLastEntryTime(schedule.slot.startTime)}
                    </td>
                  </tr>
                  
                </tbody>
              </table>
              <div className="flex justify-center items-center">
                <h1 className="text-center text-lg lg:text-xl text-red-500 w-[80%]">
                  Click the 'START EXAM' button on your dashboard at least 10
                  minutes before the exam start time; otherwise, you will miss
                  the exam and will not be able to enter.
                </h1>
              </div>
            </div>

            <div className="w-[25%]">
              <div className="h-[220px] border border-black text-xs text-center">
                Passport sized Photograph of the Candidate
                <div className="mb-2 mt-1 flex items-center justify-center">
                  <img
                    src={`${CLOUD_FRONT_URL}/${schedule.profilePic}`}
                    alt="Candidate's Photograph"
                    height={180}
                    width={180}
                    className="aspect-square"
                  />
                </div>
              </div>
              <div className="h-[120px] border border-black text-xs text-center">
                Signature of the Candidate
                <div className="my-3 flex items-center justify-center">
                  <img
                    src={`${CLOUD_FRONT_URL}/${schedule?.signature}`}
                    alt="candidate"
                    height={80}
                    width={220}
                    className="aspect-[3/1]"
                  />
                </div>
              </div>
              <div className="h-[100px] border border-black text-xs flex flex-col items-center">
                <p>Signature of the Mission Director</p>
                <img
                  src={sig}
                  alt="signature of the mission director"
                  className="w-28"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-lg text-center text-red-500 font-semibold mb-2">
            IMPORTANT INSTRUCTIONS FOR CANDIDATES
          </h3>
          <h2 className="my-2">DOS</h2>
          <ol className="list-decimal pl-4 text-sm">
            <li>A fast, stable internet connection is required.</li>
            <li>
              A Few blank white papers and a Blue Ball-pen (other colored pens
              are not allowed) are required to complete the descriptive answers.
            </li>
            <li>A pencil and eraser are needed.</li>
            <li>
              You must verify your identity using a photo ID that has your Name,
              Photo, and Signature on the same side.
            </li>
            <li>
              The candidate’s attention must remain focused on the screen
              throughout the exam.
            </li>
            <li>
              You have to show your room if asked by the invigilator during the
              Exam.
            </li>
            <li>
              A good device for appearing in the Exam (Laptop or Desktop with
              Webcam) is required.
            </li>
            <li>Exam will not be mobile compatible.</li>
            <li>
              The camera quality of the Smart Phone should be good so that the
              scan and upload quality of the long answers are well-visible (A
              Smart Phone will be allowed for uploading long answer at the
              appropriate time only)
            </li>
            <li>A live photo must be uploaded before the Exam starts.</li>
            <li>
              No candidates should adopt any unfair means, or indulge in any
              unfair examination practice.
            </li>
          </ol>
          <h2 className="my-2 text-red-500">DON'TS</h2>
          <ol className="list-decimal pl-4 text-sm">
            <li>Wearing of headphones or headsets is not permitted.</li>
            <li className="text-red-500">
              No cell phones or other secondary devices are allowed in the room
              or test area, apart from your Primary device through which you are
              appearing the Exam (A Smart Phone will be allowed for uploading
              long answer at the appropriate time only)
            </li>
            <li>No one else can be in the room with you.</li>
            <li>
              Talking or gossiping during the Examination Breaks are not
              permitted during the exam.
            </li>
            <li>
              Attending phone calls during the Examination is not allowed.
            </li>
            <li className="text-red-500">
              Candidates are not allowed to stop their Camera or Video during
              the whole examination duration.
            </li>
            <li className="text-red-500">
              Blurred background in Video Streaming is not allowed.
            </li>
            <li className="text-red-500">
              Candidates must be in Decent Dress-code in the exam, no Homely
              Dress allowed
            </li>
            <li className="text-red-500">
              Your video will be recorded. If any suspicious activities are
              observed during the exam, it may result in disqualification from
              the final interview round due to a lack of honesty.
            </li>
            <li className="text-red-500">
              Violation of any of the above and below rules and terms &
              conditions of the Examination or the Application or the company as
              well as NHWM, the candidates' candidature will be cancelled
              automatically along with disciplinary action without prior notice.
            </li>
          </ol>
        </div>
        <div className="text-sm border-t pt-4 text-justify">
          <p className="mb-1">
            NB: NHWM and its supportive organization are not responsible if you
            are unable to attend the exam due to network issues or technical
            glitches in your device.
          </p>
          <p className="mb-1">
            If appearing from an Internet Cafe, be sure that no disturbance at
            all should be in the cafe. If any candidate is found guilty of the
            rules of the Examination or found violating the Terms and Conditions
            of the organization, they will be terminated from the Exam, and no
            further attempt will be allowed.
          </p>
          <p className="mb-1">
            This rule is applicable even after completion or successful
            submission of the answer script of the Examination. If a candidate
            is found guilty in any one exam, he/she will be terminated for all
            exams he/she has already appeared in or is yet to appear in.
          </p>
          <p>
            NHWM decision will be the final decision. Candidates are not
            permitted to retake or request a re-evaluation of the exam.
            Selection will be based solely on merit. Bench-Mark will be decided
            by NHWM according to the post to be appointed by NHWM.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdmitCardStructure;
