import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialogue/radix-dialog";
import { CreateSlotForm } from "./create-slot-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CreateIndividualSlotForm } from "./create-individual-slot";

interface CreateQuestionDialogProps {
  children: React.ReactNode;
}
export const CreateSlotDialog = ({ children }: CreateQuestionDialogProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Create Slot</DialogTitle>
        </DialogHeader>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setChecked(e.target.checked)}
                checked={checked}
              />
            }
            label="Create Individual Slot"
          />
        </div>
        {checked ? <CreateIndividualSlotForm /> : <CreateSlotForm />}
      </DialogContent>
    </Dialog>
  );
};
