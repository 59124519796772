import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectGroup,
    SelectItem,
  } from "src/components/input/Select";
import { Posts } from "src/utils/posts";
  
  interface SelectPostProps {
    value: string;
    onValueChange: (value: string) => void;
  }
  
  export const SelectPost = ({ value, onValueChange }: SelectPostProps) => {
    return (
      <Select value={value} onValueChange={onValueChange}>
        <SelectTrigger>
          <SelectValue placeholder="Post" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectGroup>
            <SelectItem value="all">No filter</SelectItem>
            {Posts.map((post) => (
              <SelectItem key={post} value={post}>
                {post}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  };
  