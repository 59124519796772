import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { ManageExamHeader } from "./ManageExamHeader";
import ManageExamTable from "./ManageExamTable";

const ManageExamView = () => {
  return (
    <DashboardDefaultContainer>
      <ManageExamHeader />
      <ManageExamTable />
    </DashboardDefaultContainer>
  );
};

export default ManageExamView;
