

const FrontDeskHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <h1 className="text-2xl font-bold">Front Desk</h1>
    </div>
  )
}

export default FrontDeskHeader