import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialogue/radix-dialog";
import { EditQuestionForm } from "./edit-question-form";
import { Question } from "src/redux/types/questions";

interface EditQuestionDialogProps {
  children: React.ReactNode;
  question: Question;
}
export const EditQuestionDialog = ({
  children,
  question,
}: EditQuestionDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Edit Question</DialogTitle>
        </DialogHeader>
        <EditQuestionForm question={question} />
      </DialogContent>
    </Dialog>
  );
};
