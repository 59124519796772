/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userLogin } from "../../redux/reducers";
import { LoginAPI } from "../../redux/api/_admin.api";

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [forgot, setForgot] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const onTapSignin = async () => {
    if (email === "" || password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    try {
      const data = await LoginAPI(email, password);
      const token = data.response.AccessToken as string;
      const refreshToken = data.response.RefreshToken as string;
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        dispatch(userLogin({ token: token, _id: "" }));
        navigate("/dashboard");
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }

    // dispatch(onShowLoader());
    // dispatch(LoginAPI(email, password));
  };

  const onTapResetPassword = () => {
    if (email === "") {
      toast("Please enter email address!", {
        type: "error",
      });
      return;
    }
    // dispatch(onShowLoader("Loading..."));
    // dispatch(onResetPassword({ email }));
    // // TODO: check if email is incorporated or not after that show success message
    setTimeout(() => {
      toast("Reset password link sent to your registered password link!", {
        type: "success",
      });
      setEmail("");
      setForgot(false);
    }, 2000);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold tracking-tight text-gray-900">
          Sign in to your Admin Account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                placeholder="Your email"
                className="input w-full max-w-xs rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between max-w-xs">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
                placeholder="Your Password"
                className="input w-full max-w-xs rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              onClick={() => onTapSignin()}
              className="btn btn-wide max-w-xs bg-yellow-500 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
