import * as z from "zod";
import { StateList } from "../utils/states";
import { Posts } from "../utils/posts";
const MAX_FILE_SIZE = 1000000;

const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const states = StateList.map((state) => state.state);

export const statusEnums = ["APPROVED", "PENDING"];

export const CreateEbookUserSchema = z.object({
  fullName: z.string().min(1),
  post: z.enum(Posts),
  whatsappNumber: z
    .string()
    .regex(/^\d+$/, "Please enter a valid phone number")
    .min(10, "Phone number must be 10 digits")
    .max(10, "Phone number must be 10 digits"),
  completePostalAddress: z.string().min(1),
  paymentAmount: z.string().min(1),
  email: z.string().email().min(1),
  state: z.enum(states as [string, ...string[]]),
  pinCode: z.string().regex(/^\d+$/).min(1),
  transactionNumber: z.string().optional(),
  transactionProof: z
    .any()
    .refine((file) => file?.size <= MAX_FILE_SIZE, `Max image size is 1MB.`)
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, .png and .webp formats are supported."
    ).optional(),
  status: z.enum(statusEnums as [string, ...string[]]),
});
