import React, { FC, useEffect, useState } from "react";
import {
  getSubmissionByAdmit,
  saveResultApi,
  saveSubmissionMarks,
} from "src/redux/api/_exams.api";
import { CheckExamMarksHeader } from "./CheckExamMarksHeader";
import { ExamSubmission } from "src/redux/types/single-submission";
import { useAppSelector } from "src/redux/hooks";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "src/components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { useForm } from "react-hook-form";
import { UPLOADED_ANSWER_URL } from "src/redux/api/appConst";

interface CheckExamMarksViewProps {
  defaultView: () => void;
}

interface FormProps {
  result: string;
}

const CheckExamMarksView: FC<CheckExamMarksViewProps> = ({ defaultView }) => {
  const [currentPaper, setCurrentPaper] = useState<ExamSubmission[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const optionalQuestionSubmissions: ExamSubmission[] = [];
  const shortQuestionSubmissions: ExamSubmission[] = [];
  const longQuestionSubmissions: ExamSubmission[] = [];

  const { currentCandidateSubmission } = useAppSelector(
    (state) => state.userReducer
  );

  const fetchSubmissionByAdmit = async () => {
    setLoading(true);
    try {
      const response = await getSubmissionByAdmit(
        currentCandidateSubmission.admitCardNumber
      );

      if (response?.message.toLowerCase() === "success") {
        setCurrentPaper(response.data.exam_submissions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const questionTypeSeparator = (paper: ExamSubmission[]) => {
    paper.forEach((currentPaper) => {
      const questionType = currentPaper.question.type.toLowerCase();

      switch (questionType) {
        case "optional":
          optionalQuestionSubmissions.push(currentPaper);
          break;
        case "short":
          shortQuestionSubmissions.push(currentPaper);
          break;
        case "desc":
          longQuestionSubmissions.push(currentPaper);
          break;
        default:
          break;
      }
    });
  };

  if (currentPaper) {
    questionTypeSeparator(currentPaper!);
  }

  useEffect(() => {
    fetchSubmissionByAdmit();
  }, []);

  const [marks, setMarks] = useState({});

  const changeHandler = (id: number, value: string) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [id]: value,
    }));
  };

  const saveMarks = async (id: number) => {
    const savedMarks = Number(marks[id]);

    const response = await saveSubmissionMarks(id, savedMarks);

    if (response?.message.toLowerCase() === "success") {
      toast.success("Marks Saved!");
    } else {
      toast.error("Something went wrong while saving the Marks!");
    }
  };

  const newObtainedMarksArray: string[] = Object.values(marks);

  const calculateTotalMark = (): number => {
    return newObtainedMarksArray.reduce(
      (acc, mark) => acc + parseFloat(mark),
      0
    );
  };

  const oldObtainedMarksArray: number[] = [];

  if (currentPaper) {
    currentPaper.forEach((question) => {
      oldObtainedMarksArray.push(question.exam_submission.marks_obtained);
    });
  }

  const getTotalMarks = (): number => {
    return oldObtainedMarksArray.reduce((acc, curr) => acc + curr, 0);
  };

  const oldTotalMarks = getTotalMarks();
  const newTotalMarks = calculateTotalMark();

  const totalMarksObtained = oldTotalMarks + newTotalMarks;

  const form = useForm<FormProps>();

  const saveResult = async (values: FormProps) => {
    try {
      const result = {
        admitcard_number: currentCandidateSubmission.admitCardNumber,
        examination_id: currentCandidateSubmission.examinationId,
        post: currentCandidateSubmission.postName,
        marks: totalMarksObtained,
        result: values.result,
        candidate_name: currentCandidateSubmission.candidateName,
        state: currentCandidateSubmission.state,
      };

      const response = await saveResultApi(result);

      if (response) {
        console.log(response);
        toast.success("Result Saved Successfully!");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const OptionalMarksTable = React.memo(() => {
    if (Array.isArray(optionalQuestionSubmissions)) {
      return (
        <tbody>
          {optionalQuestionSubmissions.map((paper, index) => {
            return (
              <tr key={index}>
                <td>
                  <span>{index + 1}</span><span> .{paper.question.question}</span>
                </td>
                <td>{paper.exam_submission.answer}</td>
                <td>{paper.question.answer}</td>
                <td>{paper.exam_submission.marks_obtained}</td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return <div>Loading...</div>;
  });

  const ShortMarksTable = React.memo(() => {
    if (Array.isArray(shortQuestionSubmissions)) {
      return (
        <tbody>
          {shortQuestionSubmissions.map((paper, index) => {
            return (
              <tr key={index}>
                <td className="w-[29rem]">
                  <span>{index + 1}</span>.{" "}
                  <span>{paper.question.question}</span>
                </td>
                <td className="w-[22rem]">{paper.exam_submission.answer}</td>
                <td className="w-[29rem]">{paper.question.answer}</td>
                <td>
                  {!!paper.exam_submission.marks_obtained ? (
                    paper.exam_submission.marks_obtained
                  ) : (
                    <input
                      type="text"
                      placeholder="Marks"
                      value={marks[paper.exam_submission.id] || ""}
                      onChange={(e) =>
                        changeHandler(paper.exam_submission.id, e.target.value)
                      }
                      className="flex h-9 w-16 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    />
                  )}
                </td>
                <td>
                  {!paper.exam_submission.marks_obtained && (
                    <button
                      onClick={() => saveMarks(paper.exam_submission.id)}
                      className="px-3 py-1 rounded-md bg-green-200 hover:border hover:border-green-500 border border-black"
                    >
                      SAVE
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return null;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const DisplayLongQuestions = React.memo(() => {
    if (Array.isArray(longQuestionSubmissions)) {
      return (
        <div>
          {longQuestionSubmissions.map((paper, index) => {
            return (
              <div className="border-b border-slate-300 p-2 my-6 " key={index}>
                <h1 className="text-lg">
                  <span>{index + 1}</span>.{" "}
                   <span>{paper.question.question}</span>
                </h1>
                {!!paper.exam_submission.answerUrl ? (
                  <img
                    src={`${UPLOADED_ANSWER_URL}/${paper.exam_submission.answerUrl}`}
                    alt="uploaded answer"
                    className="aspect-[3/4] m-auto max-w-[1080px] my-4"
                  />
                ) : !!paper.exam_submission.answer ? (
                  <h1>{paper.exam_submission.answer}</h1>
                ) : (
                  <h1 className="text-xl font-bold text-center">
                    Answer Not Uploaded
                  </h1>
                )}

                <div>
                  {!!paper.exam_submission.marks_obtained ? (
                    <h1 className="text-xl font-bold text-end pr-4">
                      {" "}
                      Obtained Marks: {paper.exam_submission.marks_obtained}
                    </h1>
                  ) : (
                    <div className="flex gap-4 justify-end">
                      <input
                        type="text"
                        placeholder="Marks"
                        value={marks[paper.exam_submission.id] || ""}
                        onChange={(e) =>
                          changeHandler(
                            paper.exam_submission.id,
                            e.target.value
                          )
                        }
                        className="flex h-9 w-16 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                      />
                      <button
                        onClick={() => saveMarks(paper.exam_submission.id)}
                        className="px-3 py-1 rounded-md bg-green-200 hover:border hover:border-green-500 border border-black"
                      >
                        SAVE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <div>Loading...</div>;
  });

  return (
    <>
      <CheckExamMarksHeader defaultView={defaultView} />
      <div>
        <div className="flex gap-4 mb-8 pl-6">
          <div className="font-semibold text-xl">
            <h1>Candidate Name</h1>
            <h1>Admit Card Number</h1>
            <h1>Post Name</h1>
            <h1>State</h1>
            <h1>Exam Date</h1>
            <h1>Exam Start Time</h1>
          </div>
          <div className="font-semibold text-xl">
            <h1 className="uppercase">
              {currentCandidateSubmission.candidateName}
            </h1>
            <h1>{currentCandidateSubmission.admitCardNumber}</h1>
            <h1>{currentCandidateSubmission.postName}</h1>
            <h1>{currentCandidateSubmission.state}</h1>
            <h1>
              {moment(currentCandidateSubmission.startTime).format(
                "DD-MM-YYYY"
              )}
            </h1>
            <h1>
              {moment(currentCandidateSubmission.startTime).format("h:mm a")}
            </h1>
          </div>
          <div className="mt-12 ml-40 text-xl font-bold flex gap-8">
            <h1>
              Total Marks Obtained: <span>{totalMarksObtained}</span>
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Optional Questions Marks
          </h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Correct Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <OptionalMarksTable />
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Short Questions Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Expected Answer</th>
                <th>Marks Obtained</th>
                <th></th>
              </tr>
            </thead>
            <ShortMarksTable />
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Descriptive Questions Marks
          </h1>
          <DisplayLongQuestions />
        </div>
        <div className="my-12 text-2xl font-bold flex justify-end gap-8">
          <h1>
            Total Marks Obtained: <span>{totalMarksObtained}</span>
          </h1>
          <Form {...form}>
            <form
              className="flex gap-8"
              onSubmit={form.handleSubmit(saveResult)}
            >
              <div>
                <FormField
                  name="result"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Result" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="QUALIFIED">QUALIFIED</SelectItem>
                          <SelectItem value="NOT QUALIFIED">
                            NOT QUALIFIED
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
              <button
                type="submit"
                className="px-3 py-1 text-lg rounded-md bg-green-200 hover:bg-green-500 hover:border hover:border-green-500 border border-black"
              >
                SAVE RESULT
              </button>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CheckExamMarksView;
