import { ChevronRight } from "lucide-react";

interface NavigationCardProps {
  label: string;
  href: string;
}

export const NavigationCard = ({ label, href }: NavigationCardProps) => {
  return (
    <a href={href}>
      <div className="bg-white/30 border border-stone-700 shadow-sm hover:bg-gray-200 p-3 rounded-lg w-fit h-[80px] flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <h2 className="font-bold text-2xl">{label}</h2>
        </div>
        <ChevronRight className="size-8" />
      </div>
    </a>
  );
};
