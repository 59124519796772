import { Button } from "src/components/buttons/shadcn-button";
import { NavigateBack } from "../../../../components/ui/navigate-back";
import CreateAdmitDialog from "./createAdmitDialog";
export const ManageAdmitHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Manage Admit cards</p>
      <div className="flex gap-2">
        <NavigateBack href="/exams" />
        <CreateAdmitDialog>
          <Button variant="outline">Create Admit</Button>
        </CreateAdmitDialog>
      </div>
    </div>
  );
};
