import { Posts } from "src/utils/posts";
import { StateList } from "src/utils/states";
import { z } from "zod";

const states = StateList.map((state) => state.state);

export const EditCandidateSchema = z.object({
  fullName: z.string().min(1),
  whatsappNumber: z.string().min(1).max(10),
  email: z.string().email(),
  callingNumber: z.string().min(1).max(10),
  post: z.enum(Posts),
  address: z.string().min(1),
  pinCode: z.string().min(1).max(6),
  permanentAddress: z.string().min(1),
  baseDistrict: z.string().optional(),
  stateOfBirth: z.enum(states as [string, ...string[]]),
});
