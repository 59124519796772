import { z } from "zod";

const ACCEPTED_MIME_TYPES = ["text/csv"];

export const CreateMultipleQuestionsSchema = z.object({
  questionsCsv: z.instanceof(File).superRefine((f, ctx) => {
    if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `File must be of type ${ACCEPTED_MIME_TYPES.join(
          ","
        )} but was of type ${f.type}`,
      });
    }
  }),
});

export const questionTypes = [
  {
    label: "OPTIONAL",
    value: "optional",
  },
  {
    label: "SHORT",
    value: "short",
  },
  {
    label: "DESCRIPTIVE",
    value: "desc",
  },
] as const;

export type QuestionType = (typeof questionTypes)[number]["value"];

const Posts = [
  "ALL",
  "RWO",
  "ITWO",
  "HRWO-A",
  "HRWO-B",
  "HRWO-C",
  "HRWO-D",
  "DWO",
  "AWO",
] as const;
type Post = (typeof Posts)[number];

export const CreateSingleQuestionSchema = z.object({
  question: z.string(),
  option1: z.string().optional(),
  option2: z.string().optional(),
  option3: z.string().optional(),
  option4: z.string().optional(),
  catagory: z.enum(Posts),
  type: z.enum(
    questionTypes.map((question) => question.value) as [string, ...string[]]
  ),
  marks: z.string().superRefine((value, ctx) => {
    if (parseInt(value) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Marks cannot be negative",
      });
    }
  }),
  answer: z.string(),
  part: z.string(),
});

export type Question = {
  question: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  catagory: Post;
  type: QuestionType;
  marks: string;
  answer: string;
  part: string;
};
