import { z } from "zod";
import { questionTypes } from "./create-questions";

const Posts = [
  "ALL",
  "RWO",
  "ITWO",
  "HRWO-A",
  "HRWO-B",
  "HRWO-C",
  "HRWO-D",
  "DWO",
  "AWO",
] as const;

export const EditQuestionSchema = z.object({
  question: z.string().min(3),
  option1: z.string().optional(),
  option2: z.string().optional(),
  option3: z.string().optional(),
  option4: z.string().optional(),
  catagory: z.enum(Posts),
  type: z.enum(
    questionTypes.map((question) => question.value) as [string, ...string[]]
  ),
  marks: z.string().superRefine((value, ctx) => {
    if (parseInt(value) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Marks cannot be negative",
      });
    }
  }),
  answer: z.string().optional(),
  part: z.string(),
});
