import { ManageResultDetailsHeader } from "./ManageResultDetailsHeader";
import { useEffect, useState } from "react";
import { ExamSubmission } from "src/redux/types/single-submission";
import { getSubmissionByAdmit } from "src/redux/api/_exams.api";
import { useAppSelector } from "src/redux/hooks";

interface ManageResultDetailViewProps {
  defaultView: () => void;
}

const ManageResultDetailView = ({
  defaultView,
}: ManageResultDetailViewProps) => {
  const [currentPaper, setCurrentPaper] = useState<ExamSubmission[]>();

    const { currentResult } = useAppSelector(
      (state) => state.userReducer
    );

  const optionalQuestionSubmissions: ExamSubmission[] = [];
  const shortQuestionSubmissions: ExamSubmission[] = [];
  const longQuestionSubmissions: ExamSubmission[] = [];


  const fetchSubmissionByAdmit = async () => {
    try {
      const response = await getSubmissionByAdmit(currentResult.admitcard_number);

      if (response?.message.toLowerCase() === "success") {
        setCurrentPaper(response.data.exam_submissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const questionTypeSeparator = (paper: ExamSubmission[]) => {
    paper.forEach((currentPaper) => {
      const questionType = currentPaper.question.type.toLowerCase();

      switch (questionType) {
        case "optional":
          optionalQuestionSubmissions.push(currentPaper);
          break;
        case "short":
          shortQuestionSubmissions.push(currentPaper);
          break;
        case "desc":
          longQuestionSubmissions.push(currentPaper);
          break;
        default:
          break;
      }
    });
  };

  if (currentPaper) {
    questionTypeSeparator(currentPaper!);
  }

  useEffect(() => {
    fetchSubmissionByAdmit();
  }, []);

  const optionalMarksTable = () => {
    if (Array.isArray(optionalQuestionSubmissions)) {
      return optionalQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {paper.exam_submission.questionNumber} .{paper.question.question}
          </td>
          <td>{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return <div>Loading...</div>;
  };

  const shortMarksTable = () => {
    if (Array.isArray(shortQuestionSubmissions)) {
      return shortQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {paper.exam_submission.questionNumber}. {paper.question.question}
          </td>
          <td className="w-[40rem]">{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return <div>Loading...</div>;
  };

  const descMarksTable = () => {
    if (Array.isArray(longQuestionSubmissions)) {
      return longQuestionSubmissions.map((paper, index) => {
        return (
          <tr key={index}>
            <td>
              {paper.exam_submission.questionNumber}. {paper.question.question}
            </td>
            <td className="text-center">
              {paper.exam_submission.marks_obtained}
            </td>
          </tr>
        );
      });
    }
    return <div>Loading...</div>;
  };

  return (
    <>
      <ManageResultDetailsHeader defaultView={defaultView} />
      <div>
        <div className="flex gap-4 mb-8 pl-6">
          <div className="font-semibold text-xl">
            <h1>Candidate Name</h1>
            <h1>Post Name</h1>
            <h1>State</h1>
            <h1>Admit Card Number</h1>
          </div>
          <div className="font-semibold text-xl">
            <h1 className="uppercase">
              {currentResult.candidate_name}
            </h1>
            <h1>{currentResult.post}</h1>
            <h1>{currentResult.state}</h1>
          </div>
          <div className="ml-40 text-xl font-bold">
            <h1>
              Total Marks Obtained: <span>{currentResult.marks}</span>
            </h1>
            <h1
              className={`${
                currentResult.result.toLowerCase() === "qualified" ? "text-green-500" : "text-red-500"
              }`}
            >
              {currentResult.result}
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Optional Questions Marks
          </h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <tbody>{optionalMarksTable()}</tbody>
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Short Questions Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Marks Obtained</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{shortMarksTable()}</tbody>
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Descriptive Questions Marks
          </h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Marks Obtained</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{descMarksTable()}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ManageResultDetailView;
