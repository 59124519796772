import { useTransition } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/input/Select";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Post, Posts } from "../../../../utils/posts";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/shadcn-button";
import { Loader2 } from "lucide-react";
import { EditSlot } from "src/redux/api/_exams.api";
import { toast } from "react-toastify";
import { EditSlotSchema } from "src/schemas/edit-slot";
import { Slot } from "src/redux/types/slot";
import { states } from "src/schemas/create-candidate";
import moment from "moment";
import { format } from "date-fns";

interface EditSlotFormProps {
  slot: Slot;
}

export const EditSlotForm = ({ slot }: EditSlotFormProps) => {
  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    return format(dateObj, "MM-dd-yyyy");
  };

  const form = useForm<z.infer<typeof EditSlotSchema>>({
    resolver: zodResolver(EditSlotSchema),
    defaultValues: {
      post: slot.catagory as Post,
      state: slot.state,
      maxCapacity: slot.maxCapacity.toString(),
      startTime: moment(slot.startTime).format("hh:mm"),
      endtime: moment(slot.endtime).format("hh:mm"),
      date: formatDate(slot.date),
      zoom_link: slot.zoom_link,
      slotName: slot.slotName,
    },
  });

  const [isPending, startTransition] = useTransition();

  const onSubmit = (values: z.infer<typeof EditSlotSchema>) => {
    startTransition(() => {
      EditSlot(values, slot.id).then((data) => {
        if (data.message === "success") {
          toast.success("Slot Edited Successfully!");
        }
      });
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          name="post"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Post</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select post" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Posts.map((post) => {
                    return (
                      <SelectItem key={post} value={post}>
                        {post}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="state"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select State" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {states.map((state) => {
                    return (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="maxCapacity"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Max capacity</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Max capacity" type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Exam date</FormLabel>
              <FormControl>
                <Input {...field} placeholder="MM-DD-YYYY" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="startTime"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Start time</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="HH:MM in 24 hour format"
                  type="time"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="endtime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>End time</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="HH:MM in 24 hour format"
                  type="time"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="zoom_link"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Zoom Link</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Zoom Link" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="slotName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slot name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Slot Name" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending ? <Loader2 className="animate-spin mx-auto" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
