import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogClose,
  DialogTrigger,
  DialogDescription,
} from "src/components/dialogue/radix-dialog";

interface ReleaseSlotDialogProps {
  children: React.ReactNode;
  onRelease: () => void;
}

export const ReleaseSlotDialog = ({
  children,
  onRelease,
}: ReleaseSlotDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Release this slot?</DialogTitle>
        </DialogHeader>
        <div className="flex justify-between space-y-1">
          <DialogClose>
            <button
              className="bg-green-500/70 hover:bg-green-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              onClick={onRelease}
            >
              Release
            </button>
          </DialogClose>
          <DialogClose>
            <button className="bg-red-500/70 hover:bg-red-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
              Cancel
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
