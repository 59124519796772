import { useState } from "react";
import { SendEmails } from "../../../redux/api/notification.api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { cn } from "../../../utils";

export const EmailNotification = () => {
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const queryParmasEmail = searchParams.get("email");

  const [emails, setEmails] = useState(
    decodeURIComponent(queryParmasEmail || "") || ""
  );

  type OptionType = {
    label: string;
    value: string;
  };

  const emailTypes: OptionType[] = [
    { label: "Credential Distribution", value: "credential_distribution" },
    { label: "Exam Tutorial", value: "exam_tutorial" },
    { label: "Exam slot release", value: "exam_slot_release" },
    { label: "Rules of the Exam", value: "rules_of_the_exam" },
    { label: "Urgent Points", value: "urgent_points" },
    { label: "Re-Upload Requires", value: "reupload_requires" },
    { label: "Updates of NHWM", value: "updates_of_nhwm" },
    { label: "Final Reminder (Slot)", value: "final_reminder_slot" },
    { label: "Final Reminder (Upload)", value: "final_reminder_upload" },
    { label: "How to Install Apps", value: "how_to_install_apps" },
  ];

  const posts: OptionType[] = [
    { label: "RWO", value: "rwo" },
    { label: "ITWO", value: "itwo" },
    { label: "AWO", value: "awo" },
    { label: "HRWO-A", value: "hrwo-a" },
    { label: "HRWO-B", value: "hrwo-b" },
    { label: "HRWO-C", value: "hrwo-c" },
    { label: "HRWO-D", value: "hrwo-d" },
    { label: "DWO", value: "dwo" },
  ];

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [post, setPost] = useState(posts[0].value);

  const [emailType, setEmailType] = useState(emailTypes[0].value);

  const onSendEmails = async () => {
    try {
      // TODO: put validation message for inputs
      const emailCondense = emails.replace(/\s+/g, " ");
      const emailIds = emailCondense.split(",");
      const status = await SendEmails(
        emailIds,
        subject,
        emailType,
        post,
        emailType === "notification" ? message : undefined
      );
      if (status === 200) {
        toast(`Successfully Sent Notification!`, {
          type: "success",
        });
      }
    } catch (error) {
      console.log(error);
      toast(`Error on Sending Notification!`, {
        type: "error",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="p-4 flex justify-between">
          <p className="text-2xl">Send Email Notifications</p>
          <div className="join">
            <button
              className="btn btn-xs sm:btn-sm md:btn-md join-item"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
        </div>
        <div className="overflow-x-auto p-10">
          <div className="mt-10  pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email IDs (separate emails with a comma)
                </label>
                <div className="mt-2">
                  <textarea
                    id="emails"
                    value={emails}
                    name="emails"
                    className="textarea input-bordered w-full"
                    onChange={(e) => setEmails(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-3 mt-10">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Subject
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="input input-bordered w-full"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-3 mt-5 space-y-1">
              <label
                htmlFor="email-type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Post
              </label>
              <select
                className="border p-1 rounded-md min-w-[200px]"
                value={post}
                onChange={(e) => setPost(e.target.value)}
              >
                {posts.map((emailType) => (
                  <option key={emailType.value} value={emailType.value}>
                    {emailType.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-3 mt-5 space-y-1">
              <label
                htmlFor="email-type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email Type
              </label>
              <select
                className="border p-1 rounded-md min-w-[200px]"
                value={emailType}
                onChange={(e) => setEmailType(e.target.value)}
              >
                {emailTypes.map((emailType) => (
                  <option key={emailType.value} value={emailType.value}>
                    {emailType.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={cn(
                "mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6",
                emailType !== "notification" && "hidden"
              )}
            >
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2">
                  <textarea
                    id="message"
                    name="message"
                    rows={3}
                    className="textarea input-bordered w-full"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() => onSendEmails()}
                className="btn-success mt-10 btn btn-xs sm:btn-sm md:btn-md lg:btn-lg"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
