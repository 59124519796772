import React from "react";
import { format } from "date-fns";
import { Button } from "src/components/buttons/shadcn-button";
import { cn } from "src/utils";
import { Checkbox } from "@mui/material";
import { EditSlotDialog } from "./edit-slot-dialog";
import { Slot } from "src/redux/types/slot";
import { DeleteSlotDialog } from "./delete-slot-dialog";
import { ReleaseSlotDialog } from "./ReleaseSlotDialog";
import moment from "moment";

interface TableRowProps {
  id: number;
  date: string;
  post: string;
  state: string;
  slotName: string;
  zoom_link: string;
  selected: boolean;
  maxCapacity: number;
  startTime: string;
  endtime: string;
  isEnabled: boolean;
  isMarked;
  onSelect: (id: number) => void;
  onClick: () => void;
  onRelease: () => void;
}

const TableRow: React.FC<TableRowProps> = ({
  id,
  date,
  post,
  state,
  zoom_link,
  slotName,
  maxCapacity,
  onSelect,
  onClick,
  onRelease,
  selected,
  startTime,
  endtime,
  isEnabled,
  isMarked,
}) => {
  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    return format(dateObj, "dd-MM-yyyy");
  };

  const slot: Slot = {
    id,
    date,
    startTime,
    post,
    state,
    endtime,
    catagory: post,
    zoom_link,
    slotName,
    maxCapacity,
    isEnabled,
    isMarked,
  };

  return (
    <tr className="divide-x">
      <td className="flex gap-1 items-center">
        <Checkbox onChange={() => onSelect(id)} checked={isMarked ? (isMarked) : (selected)} />
          <span className="text-green-500 font-semibold text-lg">{isMarked ? "Done" : ""}</span>
      </td>
      <td>
        <span>{id}</span>
      </td>
      <td>{formatDate(date)}</td>
      <td>{post}</td>
      <td>{state}</td>
      <td>{slotName}</td>
      <td>{maxCapacity}</td>
      <td>{moment(startTime).format("h:mm a")}</td>
      <td>{moment(endtime).format("h:mm a")}</td>
      <td
        className={cn(
          isEnabled ? "text-green-500" : "text-red-500",
          "uppercase font-bold"
        )}
      >
        {isEnabled ? "enabled" : "disabled"}
      </td>
      <td>
        <EditSlotDialog slot={slot}>
          <Button variant="default" className="hover:bg-amber-200">
            Edit
          </Button>
        </EditSlotDialog>
      </td>
      <td>
        <ReleaseSlotDialog onRelease={onRelease}>
          <Button variant="default" className="hover:bg-green-300">
            Release Slot
          </Button>
        </ReleaseSlotDialog>
      </td>
      <td>
        <DeleteSlotDialog onClick={onClick} id={id}>
          <Button variant="destructive">Delete</Button>
        </DeleteSlotDialog>
      </td>
    </tr>
  );
};

export default TableRow;
