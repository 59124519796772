import { ArrowBack } from "@mui/icons-material";
import { useAppSelector } from "../../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { CLOUD_FRONT_URL } from "../../../redux/api/appConst";

export const ViewEbookUserDetails = () => {
  const { currentEbookUser } = useAppSelector((state) => state.userReducer);

  interface Entry {
    label: string;
    value: string;
  }

  interface DetailCardProps {
    title: string;
    entries: Entry[];
  }

  const DetailCard = ({ title, entries }: DetailCardProps) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
        {entries.map((entry) => (
          <div key={entry.label}>
            <label className="block text-gray-700 font-semibold text-lg mb-2">
              {entry.label}
            </label>
            <p className="text-gray-900">{entry.value}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto p-6">
        <div className="p-2 flex justify-between">
          <p className="text-2xl">
            E-Book User Details
          </p>
          <div className="join gap-3">
            <button
              className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
              onClick={() =>
                navigate(`/view-ebook-users?${searchParams.toString()}`)
              }
            >
              <ArrowBack />
              Back
            </button>
          </div>
        </div>
            <div className="space-y-2">
                <DetailCard
                  title="Personal Information"
                  entries={[
                    { label: "Full Name", value: currentEbookUser.fullName },
                    { label: "Email", value: currentEbookUser.emailId },
                    {
                      label: "Phone Number",
                      value: currentEbookUser.phoneNumber,
                    },
                  ]}
                />
                <DetailCard
                  title="Job Details"
                  entries={[
                    { label: "Job Post", value: currentEbookUser.jobPost },
                    { label: "SL", value: currentEbookUser.sl.toString() },
                  ]}
                />
                <DetailCard
                  title="Address Information"
                  entries={[
                    {
                      label: "PostCode",
                      value: currentEbookUser.address.PostCode,
                    },
                    { label: "State", value: currentEbookUser.address.State },
                    {
                      label: "Address",
                      value: currentEbookUser.address.address,
                    },
                  ]}
                />
              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-bold mb-4">Payment Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                  {[
                    {
                      label: "Payment Amount",
                      value: String(currentEbookUser.paymentAmount),
                    },
                    {
                      label: "Payment Image",
                      value: Array.isArray(currentEbookUser.paymentInfo)
                        ? currentEbookUser.paymentInfo.map((info) =>
                            /\.(jpg|jpeg|png|gif)$/i.test(info.FileUrl) ? (
                              <img
                                key={info.TransactionId}
                                src={`${CLOUD_FRONT_URL}/${info.FileUrl}`}
                                alt="Payment Receipt"
                                style={{ maxWidth: "300px" }} // Set the maximum width for the image
                              />
                            ) : (
                              `FileUrl: ${info.FileUrl}, TransactionId: ${info.TransactionId}`
                            )
                          )
                        : "Unavailable",
                    },
                  ].map((entry) => (
                    <div key={entry.label}>
                      <label className="block text-gray-700 font-medium mb-2">
                        {entry.label}
                      </label>
                      <p className="text-gray-900">{entry.value}</p>
                    </div>
                  ))}
                </div>
              </div>

              <DetailCard
                title="Dates"
                entries={[
                  {
                    label: "Created Date",
                    value: currentEbookUser.createdDate,
                  },
                  {
                    label: "Updated Date",
                    value: currentEbookUser.updatedDate,
                  },
                ]}
              />
              <DetailCard
                title="Status"
                entries={[{ label: "", value: currentEbookUser.status }]}
              />
            </div>
            <div className="join gap-3 py-4">
            </div>
      </div>
    </div>
  );
};
