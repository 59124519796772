/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { GetLeads } from "../../../redux/api";
import { setLeads } from "../../../redux/reducers";
import { LeadModel } from "../../../redux/types";
import { CandidateListView } from "./CandidateListView";
import { CreateCandidate } from "./CreateCandidate";
import { CandidateDetails } from "./CandidateDetails";
import { setCurrentCandidate } from "../../../redux/reducers";
import { Candidate } from "../../../redux/types/_candidate.type";

interface ManageCandidatesProps {}

type ViewComponent = "list" | "create" | "details" | "edit" | "delete";

export const ManageCandidates: React.FC<ManageCandidatesProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [currentView, setCurrentView] = useState<ViewComponent>("list");

  const userReducer = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    // onFetchLeads();
  }, []);

  const setDefaultView = () => {
    setCurrentView("list");
  };

  const onFetchLeads = async () => {
    try {
      const data = await GetLeads();
      const userData = data as {
        message: string;
        data: { count: number; leads: LeadModel[] };
      };
      if (userData.data) {
        const { leads } = userData.data;
        dispatch(setLeads(leads));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // TODO: set candidate model instead of any
  const onTapDetails = (candidate: Candidate) => {
    // set current Candidate
    dispatch(setCurrentCandidate(candidate));
    setCurrentView("details");
  };

  const renderView = () => {
    switch (currentView) {
      case "list":
        return (
          <CandidateListView
            onTapDetails={(candidate) => onTapDetails(candidate)}
          />
        );
      case "create":
        return <CreateCandidate onSubmit={() => {}} onClose={setDefaultView} />;
      case "details":
        return (
          <CandidateDetails
            onSubmit={() => {}}
            onClose={setDefaultView}
          />
        );
      default:
        break;
    }
    return <></>;
  };

  return (
    <>
      <AppAlert
        open={alertOpen}
        title={`some title!`}
        message={"Are you sure to change?"}
        onClose={() => {
          setAlertOpen(false);
        }}
        onConfirm={() => {
          setAlertOpen(false);
        }}
      />
      <div className="container mx-auto px-4 mt-12">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          {currentView === "list" && (
            <div className="p-4 flex justify-between">
              <p className="text-2xl">Manage Candidates</p>
              <div className="join">
                <button
                  className="btn btn-xs sm:btn-sm md:btn-md join-item"
                  onClick={() => setCurrentView("create")}
                >
                  Create
                </button>
              </div>
            </div>
          )}
          <div className="overflow-x-auto">{renderView()}</div>
        </div>
      </div>
    </>
  );
};
