import { Button } from "../../../components/buttons/shadcn-button";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "../../../components/dialogue/radix-dialog";
import { CLOUD_FRONT_URL } from "../../../redux/api/appConst";
import { EbookUser } from "../../../redux/types/_ebook-user.type";

interface TransactionDetailDialogProps {
  children: React.ReactNode;
  bookUser: EbookUser;
}

export const TransactionDetailDialog = ({
  children,
  bookUser,
}: TransactionDetailDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Transaction details</DialogTitle>
        </DialogHeader>
        <section>
          {Array.isArray(bookUser.paymentInfo) &&
            bookUser.paymentInfo.map((payment) => (
              <div
                className="p-1 border rounded-md"
                key={payment.TransactionId}
              >
                <div>Transaction ID: {payment.TransactionId}</div>
                <div>Transaction Amount: {bookUser.paymentAmount}</div>
                <div className="flex flex-col gap-y-1">
                  <img
                    src={`${CLOUD_FRONT_URL}/${payment.FileUrl}`}
                    className="max-h-[400px] max-w-fit"
                  />
                  <Button asChild>
                    <a href={`${CLOUD_FRONT_URL}/${payment.FileUrl}`}>
                      View original
                    </a>
                  </Button>
                </div>
              </div>
            ))}
        </section>
      </DialogContent>
    </Dialog>
  );
};
