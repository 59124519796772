import { z } from "zod";
import { Posts } from "../utils/posts";
import { states } from "./create-candidate";

export const CreateIndividualSlotSchema = z.object({
  email: z.string().email(),
  post: z.enum(Posts),
  state: z.enum(states as [string, ...string[]]),
  date: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  zoom_link: z.string().url(),
  slotName: z.string().max(1),
});
