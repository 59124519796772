import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "src/components/dialogue/radix-dialog";
import AdmitCardStructure from "./AdmitCardStructure";
import { Schedule } from "src/redux/types/admit-card";

interface ViewAdmitDialogProps {
  children: React.ReactNode;
  schedule: Schedule;
}

export const ViewAdmitDialog = ({
  children,
  schedule,
}: ViewAdmitDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white h-[95vh] overflow-y-auto max-w-[1000px]">
        <AdmitCardStructure schedule={schedule} />
      </DialogContent>
    </Dialog>
  );
};
