import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/form/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../../components/input/input";
import { Button } from "../../../../components/buttons/shadcn-button";
import { Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/input/Select";
import {
  CreateSingleQuestionSchema,
  QuestionType,
  questionTypes,
} from "src/schemas/create-questions";
import { useState } from "react";
import { Textarea } from "src/components/input/TextArea";
import { uploadSingleQuestion } from "src/redux/api/_exams.api";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
export const CreateSingleQuestionForm = () => {
  const form = useForm<z.infer<typeof CreateSingleQuestionSchema>>({
    resolver: zodResolver(CreateSingleQuestionSchema),
  });

  const Posts = [
    "ALL",
    "RWO",
    "ITWO",
    "HRWO-A",
    "HRWO-B",
    "HRWO-C",
    "HRWO-D",
    "DWO",
    "AWO",
  ] as const;

  const [isPending, setIsPending] = useState<boolean>();

  const [questionType, setQuestionType] = useState<QuestionType>();

  const onSubmit = (values: z.infer<typeof CreateSingleQuestionSchema>) => {
    try {
      setIsPending(true);
      uploadSingleQuestion(values).then(({ status }) => {
        if (status === HttpStatusCode.Ok) {
          toast.success("Question uploaded successfully");
        }
        const questionCreatedEvent = new Event("questionCreated");
        window.dispatchEvent(questionCreatedEvent);
      });
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          name="catagory"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select post</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select post" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Posts.map((post) => {
                    return (
                      <SelectItem key={post} value={post}>
                        {post}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="type"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Question type</FormLabel>
              <Select
                onValueChange={(value) => {
                  field.onChange(value);
                  setQuestionType(value as QuestionType);
                }}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select question type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {questionTypes.map((post) => {
                    return (
                      <SelectItem key={post.value} value={post.value}>
                        {post.label}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="question"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Question</FormLabel>
              <FormControl>
                <Textarea {...field} placeholder="Your question here" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          {questionType === "optional" && (
            <div className="flex flex-wrap gap-2 justify-around">
              <FormField
                name="option1"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Option 1</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option 1" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="option2"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Option 2</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option 2" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="option3"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Option 3</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option 3" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="option4"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Option 4</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Option 4" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </div>
        <FormField
          control={form.control}
          name="answer"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Answer</FormLabel>
              <FormControl>
                <Textarea {...field} placeholder="The answer here" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-2 gap-2">
          <FormField
            name="marks"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Marks</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Marks" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {["1", "2", "5"].map((mark) => {
                      return (
                        <SelectItem key={mark} value={mark}>
                          {mark}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="part"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Part</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Part" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {["Part_1", "Part_2"].map((part) => {
                      return (
                        <SelectItem key={part} value={part}>
                          {part}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button disabled={isPending} type="submit">
          {isPending ? <Loader2 className="animate-spin mx-auto" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
