import { z } from "zod";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";
import { CreateCandidateSchema } from "../../schemas/create-candidate";
import axios from "axios";
import { EditCandidateSchema } from "src/schemas/edit-candidate";

// Move to user api
export const GetCandidatesApi = async (
  query: string,
  page: number = 1,
  pageSize: number = 100
) => {
  try {
    const api = axiosAuth();
    const response = await api.get(
      `${BASE_URL}/get-candidates?page=${page}&pagesize=${pageSize}&${query}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const UpdateCandidatesStatusApi = async (
  ids: number[],
  status: "APPROVED" | "PENDING"
) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(
      `${BASE_URL}/change-candidate-status-multiple`,
      {
        ids,
        status,
      }
    );
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const DeleteCandidateSingleApi = async (id: number) => {
  const api = axiosAuth();

  const response = await api.delete(`${BASE_URL}/delete-candidate/${id}`);

  return response;
};

async function uploadFile(file: any) {
  if (!file) return;
  try {
    const api = axiosAuth();

    const { data } = await api.get(
      `${BASE_URL}/get-upload-url?file=${file.name}`
    );

    const { Key, url } = data.data;

    await axios.put(url, file);

    return Key;
  } catch (error) {
    console.error(error);
  }
}

export const saveCandidateSingle = async (
  values: z.infer<typeof CreateCandidateSchema>
) => {
  try {
    const api = axiosAuth();

    const {
      emailAddress,
      baseDistrict,
      candidateName,
      adharNumber,
      fatherName,
      motherName,
      address,
      pinCode,
      permanentAddress,
      stateOfBirth,
      dateOfBirth,
      age,
      sex,
      religion,
      nationality,
      category,
      maritalStatus,
      whatsAppNo,
      callingMobileNo,
      educationalQualification,
      percentageOfMarksInClass10InClass12HighestEducation,
      writeAboutYourExperienceIfAny,
      declaration,
      paymentTransactionNo,
      successfulTransactionScreenshots,
      passportSizedRecentPhotograph,
      panCard,
      aadharCard,
      signature,
      handWritingSample,
      class10ThMarkSheet,
      intermediateMarksSheet,
      bachelorDegreeMarkSheet,
      experienceCertificate,
      scStObcHstPhCertificate,
      profilePicture,
      post,
    } = values;

    const successfulTransactionScreenshotsKey = await uploadFile(
      successfulTransactionScreenshots
    );
    const passportSizedRecentPhotographKey = await uploadFile(
      passportSizedRecentPhotograph
    );
    const panCardKey = await uploadFile(panCard);
    const aadharCardKey = await uploadFile(aadharCard);
    const signatureKey = await uploadFile(signature);
    const handWritingSampleKey = await uploadFile(handWritingSample);
    const class10ThMarkSheetKey = await uploadFile(class10ThMarkSheet);
    const intermediateMarksSheetKey = await uploadFile(intermediateMarksSheet);
    const bachelorDegreeMarkSheetKey = await uploadFile(
      bachelorDegreeMarkSheet
    );
    const experienceCertificateKey = await uploadFile(experienceCertificate);
    const scStObcHstPhCertificateKey = await uploadFile(
      scStObcHstPhCertificate
    );
    const profilePictureKey = await uploadFile(profilePicture);

    const payload = {
      emailAddress: emailAddress || "",
      baseDistrict: baseDistrict || "",
      candidateName: candidateName || "",
      adharNumber: adharNumber || "",
      fatherName: fatherName || "",
      motherName: motherName || "",
      address: address || "",
      pinCode: pinCode || "",
      permanentAddress: permanentAddress || "",
      stateOfBirth: stateOfBirth || "",
      dateOfBirth: dateOfBirth || "",
      age: age || "",
      sex: sex || "",
      religion: religion || "",
      nationality: nationality || "",
      category: category || "",
      maritalStatus: maritalStatus || "",
      whatsAppNo: whatsAppNo || "",
      callingMobileNo: callingMobileNo || "",
      educationalQualification: educationalQualification || "",
      percentageOfMarksInClass10InClass12HighestEducation:
        percentageOfMarksInClass10InClass12HighestEducation || "",
      writeAboutYourExperienceIfAny: writeAboutYourExperienceIfAny || "",
      declaration: declaration || "",
      paymentTransactionNo: paymentTransactionNo || "",
      post: post || "",
      successfulTransactionScreenshots:
        successfulTransactionScreenshotsKey || "",
      passportSizedRecentPhotograph: passportSizedRecentPhotographKey || "",
      panCard: panCardKey || "",
      aadharCard: aadharCardKey || "",
      signature: signatureKey || "",
      handWritingSample: handWritingSampleKey || "",
      class10ThMarkSheet: class10ThMarkSheetKey || "",
      intermediateMarksSheet: intermediateMarksSheetKey || "",
      bachelorDegreeMarkSheet: bachelorDegreeMarkSheetKey || "",
      experienceCertificate: experienceCertificateKey || "",
      scStObcHstPhCertificate: scStObcHstPhCertificateKey || "",
      profilePicture: profilePictureKey || "",
    };

    console.log({ payload });

    const response = await api.post(`${BASE_URL}/add-candidate`, payload);

    return { status: response.status };
  } catch (error) {
    console.error(error);
    return { error: "Failed to create candidate" };
  }
};

export const EditCandidate = async (
  values: z.infer<typeof EditCandidateSchema> & { post: string },
  id: string
) => {
  const api = axiosAuth();

  const response = await api.patch(`${BASE_URL}/edit-candidate/${id}`, {
    emailAddress: values.email,
    candidateName: values.fullName,
    post: values.post,
    stateOfBirth: values.stateOfBirth,
    baseDistrict: values.baseDistrict,
    address: values.permanentAddress,
    whatsAppNo: values.whatsappNumber,
    callingMobileNo: values.callingNumber,
  });

  return response.data;
};
