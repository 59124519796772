import { ArrowBack } from "@mui/icons-material";
import { FC, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { CLOUD_FRONT_URL } from "../../../redux/api/appConst";
import { Button } from "../../../components/buttons/shadcn-button";
import {
  EditCandidate,
  UpdateCandidatesStatusApi,
} from "../../../redux/api/_candidates.api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { EditCandidateSchema } from "src/schemas/edit-candidate";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormMessage,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "src/components/form/form";
import { Input } from "src/components/input/input";
import { ReceiptText, Pencil } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { states } from "src/schemas/create-candidate";
import { Posts } from "src/utils/posts";

interface CandidateDetailsProps {
  onClose: Function;
  onSubmit: Function;
}

interface Entry {
  label: string;
  value: string;
}

interface DetailCardProps {
  title: string;
  entries: Entry[];
}

const onActivatedCandidates = async (
  status: "APPROVED" | "PENDING",
  ids: number[]
) => {
  try {
    const statusCode = await UpdateCandidatesStatusApi(ids, status);
    if (statusCode === 200) {
      toast(`Successfully Changed the status to ${status} !`, {
        type: "success",
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const DetailCard = ({ title, entries }: DetailCardProps) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
      {entries.map((entry) => (
        <div key={entry.label}>
          <label className="block text-gray-700 font-medium mb-2">
            {entry.label}
          </label>
          <p className="text-gray-900">{entry.value}</p>
        </div>
      ))}
    </div>
  </div>
);

const EditCard = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    <div>{children}</div>
  </div>
);

type View = "details" | "edit";

export const CandidateDetails: FC<CandidateDetailsProps> = ({
  onClose,
  onSubmit,
}) => {
  const { currentCandidate } = useAppSelector((state) => state.userReducer);

  const form = useForm<z.infer<typeof EditCandidateSchema>>({
    resolver: zodResolver(EditCandidateSchema),
    defaultValues: {
      fullName: currentCandidate.candidateName,
      whatsappNumber: currentCandidate.whatsAppNo,
      email: currentCandidate.emailAddress,
      callingNumber: currentCandidate.callingMobileNo,
      address: currentCandidate.address,
      pinCode: currentCandidate.pinCode,
      permanentAddress: currentCandidate.permanentAddress,
      baseDistrict: currentCandidate.baseDistrict,
      stateOfBirth: currentCandidate.stateOfBirth,
    },
  });

  const [view, setView] = useState<View>("details");

  const personalInfo: Entry[] = [
    {
      label: "Candidate Name",
      value: currentCandidate.candidateName,
    },
    {
      label: "Whatsapp number",
      value: currentCandidate.whatsAppNo,
    },
    {
      label: "Email address",
      value: currentCandidate.emailAddress,
    },
    {
      label: "Calling number",
      value: currentCandidate.callingMobileNo,
    },
    {
      label: "Applied Post",
      value: currentCandidate.post,
    },
    {
      label: "Submission time",
      value: new Date(currentCandidate.timestamp).toUTCString(),
    },

    {
      label: "Age",
      value: currentCandidate.age,
    },
    {
      label: "Sex",
      value: currentCandidate.sex,
    },
    {
      label: "Religion",
      value: currentCandidate.religion,
    },
    {
      label: "Category",
      value: currentCandidate.category,
    },
    {
      label: "Nationality",
      value: currentCandidate.nationality,
    },
    {
      label: "Marital status",
      value: currentCandidate.maritalStatus,
    },
  ];

  const identificationInfo: Entry[] = [
    {
      label: "Candidate ID",
      value: String(currentCandidate.id),
    },
    {
      label: "Aadhar Card Image",
      value: currentCandidate.aadharCard,
    },
    {
      label: "Pan Card Image",
      value: currentCandidate.panCard,
    },
    {
      label: "Aadhar number",
      value: currentCandidate.adharNumber,
    },
  ];

  const addressInfo: Entry[] = [
    {
      label: "Address",
      value: currentCandidate.address,
    },
    {
      label: "Pincode",
      value: currentCandidate.pinCode,
    },
    {
      label: "Permanent address",
      value: currentCandidate.permanentAddress,
    },
    {
      label: "Base district",
      value: currentCandidate.baseDistrict,
    },
    {
      label: "State of birth",
      value: currentCandidate.stateOfBirth,
    },
  ];

  const educationalInfo: Entry[] = [
    {
      label: "Educational qualification",
      value: currentCandidate.educationalQualification,
    },
    {
      label:
        "Percentage of marks in class 10 and in class 12 or highest education",
      value:
        currentCandidate.percentageOfMarksInClass10InClass12HighestEducation,
    },
  ];

  const experienceInfo: Entry[] = [
    {
      label: "Write about your experience if any",
      value: currentCandidate.writeAboutYourExperienceIfAny,
    },
    {
      label: "Experience certificate",
      value: currentCandidate.experienceCertificate,
    },
  ];

  const documentationInfo: Entry[] = [
    {
      label: "Passport Sized Recent Photograph",
      value: currentCandidate.passportSizedRecentPhotograph,
    },
    {
      label: "Signature",
      value: currentCandidate.signature,
    },
    {
      label: "Handwriting Sample",
      value: currentCandidate.handWritingSample,
    },
    {
      label: "Class 10th Mark Sheet",
      value: currentCandidate.class10ThMarkSheet,
    },
    {
      label: "Intermediate Marks Sheet",
      value: currentCandidate.intermediateMarksSheet,
    },
    {
      label: "Bachelor's Degree Mark Sheet",
      value: currentCandidate.bachelorDegreeMarkSheet,
    },
    {
      label: "SC/ST/OBC/HST/PH Certificate",
      value: currentCandidate.scStObcHstPhCertificate,
    },
    {
      label: "Profile Picture",
      value: currentCandidate.profilePicture,
    },
  ];

  const miscellaneousInfo: Entry[] = [
    {
      label: "Declaration",
      value: currentCandidate.declaration,
    },
    {
      label: "Status",
      value: currentCandidate.status,
    },
  ];

  const onFormSubmit = (values: z.infer<typeof EditCandidateSchema>) => {
    const id = currentCandidate.id;
    EditCandidate(
      {
        ...values,
      },
      String(id)
    ).then((response) => {
      if (response.message === "success") {
        toast.success("Candidate details updated successfully");
      }
    });
  };

  return (
    <Form {...form}>
      <div className="container mx-auto px-4 mt-12">
        <form
          onSubmit={form.handleSubmit(onFormSubmit)}
          className="relative overflow-x-auto p-6"
        >
          <div className="p-2 flex justify-between">
            <p className="text-2xl">Candidate Details</p>
            <div className="join gap-3">
              <button
                className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onClose()}
              >
                <ArrowBack />
                Back
              </button>
              <button
                className="btn btn-warning btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => {
                  if (view === "details") {
                    setView("edit");
                    return;
                  }
                  setView("details");
                }}
              >
                {view === "edit" ? (
                  <div className="flex gap-2 items-center">
                    Details <ReceiptText className="size-4" />
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    Edit <Pencil className="size-4" />
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className="space-y-2">
            {view === "details" ? (
              <DetailCard title="Personal Information" entries={personalInfo} />
            ) : (
              <EditCard title="Personal Information">
                <div className="grid grid-cols-4 w-full gap-3">
                  <FormField
                    control={form.control}
                    name="fullName"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Full Name
                        </label>
                        <FormControl>
                          <Input placeholder="Full Name" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="whatsappNumber"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Whatsapp Number
                        </label>
                        <FormControl>
                          <Input placeholder="Whatsapp Number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Email
                        </label>
                        <FormControl>
                          <Input placeholder="Email" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="callingNumber"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Calling Number
                        </label>
                        <FormControl>
                          <Input placeholder="Calling Number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="post"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Candidate's post</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={currentCandidate.post}
                                {...field}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {Posts.map((post) => {
                              return (
                                <SelectItem key={post} value={post}>
                                  {post}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </EditCard>
            )}
            <DetailCard
              title="Identification Information"
              entries={identificationInfo}
            />
            {view === "details" ? (
              <DetailCard title="Address Information" entries={addressInfo} />
            ) : (
              <EditCard title="Address Information">
                <div className="grid grid-cols-3 gap-3 w-full">
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Address
                        </label>
                        <FormControl>
                          <Input placeholder="Address" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pinCode"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Pin Code
                        </label>
                        <FormControl>
                          <Input placeholder="Pin code" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="permanentAddress"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Permanent address
                        </label>
                        <FormControl>
                          <Input placeholder="Permanent address" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="baseDistrict"
                    render={({ field }) => (
                      <FormItem>
                        <label className="block text-gray-700 font-medium mb-2">
                          Base district
                        </label>
                        <FormControl>
                          <Input placeholder="Base District" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="stateOfBirth"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>State</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select Candidate's post" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {states.map((post) => {
                              return (
                                <SelectItem key={post} value={post}>
                                  {post}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </EditCard>
            )}
            <DetailCard
              title="Educational Information"
              entries={educationalInfo}
            />
            <DetailCard
              title="Experience Information"
              entries={experienceInfo}
            />
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold mb-4">Payment Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                {[
                  {
                    label: "Transaction  Number",
                    value: String(currentCandidate.paymentTransactionNo),
                  },
                  {
                    label: "Payment Image",
                    value:
                      currentCandidate.successfulTransactionScreenshots.includes(
                        "drive.google.com"
                      ) ? (
                        <a
                          href={
                            currentCandidate.successfulTransactionScreenshots
                          }
                        >
                          {currentCandidate.successfulTransactionScreenshots}
                        </a>
                      ) : (
                        <img
                          src={`${CLOUD_FRONT_URL}/${currentCandidate.successfulTransactionScreenshots}`}
                          alt="Payment Receipt"
                          style={{ maxWidth: "300px" }} // Set the maximum width for the image
                        />
                      ),
                  },
                ].map((entry) => (
                  <div key={entry.label}>
                    <label className="block text-gray-700 font-medium mb-2">
                      {entry.label}
                    </label>
                    <p className="text-gray-900">{entry.value}</p>
                  </div>
                ))}
              </div>
            </div>
            <DetailCard
              title="Documentation Information"
              entries={documentationInfo}
            />
            <DetailCard title="Other Information" entries={miscellaneousInfo} />
          </div>
          <div className="join gap-3 py-3">
            <Button
              type="button"
              size="lg"
              onClick={() => {
                onClose();
                onActivatedCandidates(
                  currentCandidate.status === "PENDING"
                    ? "APPROVED"
                    : "PENDING",
                  [currentCandidate.id]
                );
              }}
            >
              {currentCandidate.status === "PENDING" ? "APPROVE" : "DEACTIVATE"}
            </Button>
            <Button size="lg" type="submit">
              Save details
            </Button>
          </div>
        </form>
      </div>
    </Form>
  );
};
