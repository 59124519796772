import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "src/components/input/Select";

interface SelectStatusProps {
  value: string;
  onValueChange: (value: string) => void;
}

export const SelectStatus = ({ value, onValueChange }: SelectStatusProps) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder="Status" />
      </SelectTrigger>
      <SelectContent className="bg-white">
        <SelectGroup>
          <SelectItem value="all">No filter</SelectItem>
          {["APPROVED", "PENDING"].map((post) => (
            <SelectItem key={post} value={post}>
              {post}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
