import { NavigationCard } from "../../../components/ui/navigation-card";
import { DashboardDefaultContainer } from "../dashboard-default-container";
import { ExamsCardHeader } from "./exams-card-header";

export const ExamsView = () => {
  return (
    <DashboardDefaultContainer>
      <ExamsCardHeader />
      <div className="flex flex-wrap gap-3 p-3 justify-center">
        <NavigationCard label="Manage Questions" href="/manage-questions" />
        <NavigationCard label="Manage Slots" href="/manage-slots" />
        <NavigationCard label="Manage Admit Card" href="/manage-admit" />
        <NavigationCard label="Manage Exam" href="/manage-exam" />
        <NavigationCard label="Check Paper" href="/check-exam" />
        <NavigationCard label="View Results" href="/manage-results" />
      </div>
    </DashboardDefaultContainer>
  );
};
