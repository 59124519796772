import axios from "axios";
import { BASE_URL } from "./appConst";

export const LoginAPI = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, {
      username,
      password,
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};
