import { Posts } from "src/utils/posts";
import { z } from "zod";
import { states } from "./create-candidate";

export const statusEnums = ["SCHEDULED", "APPEARED"];

export const CreateAdmitSchema = z.object({
  rollNo: z.string(),
  applicationId: z.string(),
  status: z.enum(statusEnums as [string, ...string[]]),
  postName: z.enum(Posts),
  state: z.enum(states as [string, ...string[]]),
  date: z.string(),
  startTime: z.string(),
  maxCapacity: z.string(),
  endTime: z.string(),
  zoom_link: z.string(),
  slotName: z.string(),
  candidateId: z.string(),
});
