/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  CloseButton,
  ButtonWithIcon,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";

import { CLIENT_URL } from "../../../utils";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColor";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { LocationBadge } from "../../../components/misc/LocationBagde";
import { useAppSelector } from "../../../redux/hooks";
interface DashboardProps {}

export const Profile: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [onlineUrl, setOnlineUrl] = useState("N/A");
  const [isEdit, setIsEdit] = useState(false);
  const [enableStripe, setEnableStripe] = useState(false);
  const [customerWillPay, setCustomerWillPay] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const onTapUpdateProfile = () => {
    if (
      firstName.length < 3 ||
      lastName.length < 3 ||
      phoneNumber.length < 6 ||
      businessName.length < 3 ||
      businessAddress.length < 3 ||
      businessAddress.length < 3 ||
      cityName.length < 3 ||
      stateName.length < 3
    ) {
      toast("Please enter required information to update profile!", {
        type: "error",
      });
      return;
    }
    setIsEdit(false);
  };

  const hostContainer = () => {
    return (
      <ColDiv>
        <AppAlert
          open={open}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => setOpen(false)}
          onConfirm={() => {}}
        />
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            width: "80%",
            display: "row",
            maxWidth: "900px",

            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Spacer size={2} direction="row" />
            <Lbl title={`Profile`} size={32} bold={600} />
          </RowDiv>
          {!isEdit ? (
            <ButtonWithIcon
              icon={
                <EditIcon
                  style={{
                    color: AppCSS.RED,
                  }}
                />
              }
              onTap={() => setIsEdit(true)}
            />
          ) : (
            <CloseButton onTap={() => setIsEdit(false)} />
          )}
        </RowDiv>
        <Spacer size={1} direction="col" />
        {/* Selected Action row */}

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ColDiv
            style={{
              width: "60%",
              marginRight: "100px",
            }}
          >
            <Spacer size={1} direction="col" />
            <RowDiv
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <ColDiv>
                <Lbl title="First Name" color={AppCSS.GRAY_DARK} size={13} />
                <TxtInput
                  disable={!isEdit}
                  value={firstName}
                  placeholder="First Name"
                  onChange={setFirstName}
                />
              </ColDiv>
              <Spacer size={1} direction="row" />
              <ColDiv>
                <Lbl title="Last Name" color={AppCSS.GRAY_DARK} size={13} />
                <TxtInput
                  disable={!isEdit}
                  value={lastName}
                  placeholder="Last Name"
                  onChange={setLastName}
                />
              </ColDiv>
            </RowDiv>
            <ColDiv>
              <Lbl title="Business Name" color={AppCSS.GRAY_DARK} size={13} />

              <TxtInput
                disable={!isEdit}
                value={businessName}
                placeholder="Business Name"
                onChange={setBusinessName}
              />
            </ColDiv>
            <ColDiv>
              <Lbl title="Phone Number" color={AppCSS.GRAY_DARK} size={13} />

              <TxtInput
                disable={!isEdit}
                value={phoneNumber}
                placeholder="Phone Number"
                onChange={setPhoneNumber}
              />
            </ColDiv>
            <Spacer size={1} direction="col" />

            <ColDiv>
              <RowDiv
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <Lbl
                  title="Business Address"
                  color={AppCSS.GRAY_DARK}
                  size={13}
                />
                <TapButton
                  radius={30}
                  title="Edit Location"
                  onTap={() => navigate("/location")}
                  width={120}
                  height={38}
                />
              </RowDiv>

              <TxtInput
                value={businessAddress}
                disable={!isEdit}
                placeholder="Business Address"
                onChange={setBusinessAddress}
              />
            </ColDiv>
            <RowDiv
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <ColDiv>
                <Lbl title="City Name" color={AppCSS.GRAY_DARK} size={13} />

                <TxtInput
                  disable={!isEdit}
                  value={cityName}
                  placeholder="City Name"
                  onChange={setCityName}
                />
              </ColDiv>
              <Spacer size={1} direction="row" />
              <ColDiv>
                <Lbl title="State Name" color={AppCSS.GRAY_DARK} size={13} />

                <TxtInput
                  disable={!isEdit}
                  value={stateName}
                  placeholder="State Name"
                  onChange={setStateName}
                />
              </ColDiv>
            </RowDiv>

            <Spacer size={2} direction="col" />
            {isEdit && (
              <TapButton
                title="Save"
                onTap={() => onTapUpdateProfile()}
                bgColor={AppCSS.RED}
                width={240}
                height={38}
              />
            )}
          </ColDiv>
          <ColDiv
            style={{
              width: "60%",
              marginRight: "50px",
              alignItems: "center",
            }}
          >
            <ColDiv
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColDiv
                ref={componentRef}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="qrprint"
              >
                <Lbl title={businessName} size={16} align="center" />
                <Spacer size={1} direction="col" />
                <QRCode
                  value={`${CLIENT_URL}?id=${localStorage.getItem("vendorId")}`}
                  width="100px"
                  height="100px"
                />
                <Spacer size={1} direction="col" />
              </ColDiv>

              <RowDiv
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TapButton
                  onTap={() => {
                    navigate("/qrcode");
                  }}
                  title="View"
                  bgColor={AppCSS.RED}
                  color={AppCSS.WHITE}
                  radius={30}
                  width={100}
                  height={30}
                />
                <Spacer size={1} direction="col" />
                <TapButton
                  onTap={() => {}}
                  title="Print"
                  bgColor={AppCSS.BLUE}
                  color={AppCSS.WHITE}
                  radius={30}
                  width={100}
                  height={30}
                />
              </RowDiv>
              <Spacer size={1} direction="col" />
            </ColDiv>
            <Spacer size={1} direction="col" />
            <RowDiv>
              <Lbl
                title="Online URL"
                color={AppCSS.GRAY_DARK}
                size={13}
                width={80}
              />

              <div
                style={{
                  margin: "5px",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  <a
                    style={{
                      color: AppCSS.RED,
                    }}
                    href={onlineUrl}
                    target="_blank"
                  >
                    {onlineUrl}
                  </a>
                </li>
              </div>
              {(onlineUrl === undefined ||
                onlineUrl === "N/A" ||
                onlineUrl === "") && (
                <TapButton
                  width={120}
                  height={32}
                  radius={16}
                  onTap={() => {}}
                  title="Generate URL"
                />
              )}
            </RowDiv>
            <Spacer size={1} direction="col" />
            <RowDiv
              style={{
                justifyContent: "flex-start",
                flexDirection: "row",
                margin: 0,
              }}
            >
              <FormControlLabel
                style={{
                  color: "#979797",
                }}
                control={
                  <Checkbox
                    disabled={!isEdit}
                    onChange={(e) => setCustomerWillPay(!customerWillPay)}
                    sx={{
                      color: !isEdit ? AppCSS.GRAY : "#dc2342",
                      "&.Mui-checked": {
                        color: !isEdit ? AppCSS.GRAY : "#dc2342",
                      },
                    }}
                    checked={customerWillPay}
                  />
                }
                label=""
              />
              <p
                style={{
                  textAlign: "left",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.8rem",
                }}
              >
                Check if you would like to pass the 2.9% + 29 cent{" "}
                <b>Stripe </b>
                surcharge fee to your customers.
              </p>
            </RowDiv>
            <ColDiv>
              {enableStripe ? (
                <>
                  <Lbl
                    title="Stripe Account Status"
                    color={AppCSS.GRAY_DARK}
                    size={13}
                  />
                  <Lbl
                    title="Active"
                    color={AppCSS.GRAY}
                    size={13}
                    bold={600}
                  />
                </>
              ) : (
                <>
                  <Lbl
                    title="Your stripe account setup is incomplete."
                    color={AppCSS.WARNING}
                    size={12}
                  />
                  <TapButton
                    radius={30}
                    title="Setup Now"
                    onTap={() => {}}
                    width={120}
                    height={38}
                  />
                </>
              )}
            </ColDiv>
            <Spacer size={1} direction="col" />
            <ColDiv>
              <Lbl title="Email" color={AppCSS.GRAY_DARK} size={13} />

              <TxtInput
                value={email}
                placeholder="Email"
                onChange={setEmail}
                disable
              />
            </ColDiv>

            <ColDiv>
              <Lbl title="Password" color={AppCSS.GRAY_DARK} size={13} />

              <TapButton
                title="Send reset link"
                onTap={() => setOpen(true)}
                bgColor={AppCSS.GRAY_LIGHT}
                color={AppCSS.RED}
                height={32}
                width={220}
              />
            </ColDiv>
          </ColDiv>
        </RowDiv>
      </ColDiv>
    );
  };

  return hostContainer();
};
