import { ArrowBack } from "@mui/icons-material";
import { FC } from "react";

interface ManageResultDetailsHeaderProps {
  defaultView: () => void;
}

export const ManageResultDetailsHeader: FC<ManageResultDetailsHeaderProps> = ({
  defaultView,
}) => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Result Details</p>
      <div
        className="flex cursor-pointer hover:bg-amber-300 bg-zinc-200 p-2 rounded-md items-center gap-3"
        onClick={defaultView}
      >
        <ArrowBack />
        <span>Back</span>
      </div>
    </div>
  );
};
