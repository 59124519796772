import { useState } from "react"
import CheckExamListView from "./CheckExamListView";
import CheckExamMarksView from "./CheckExamMarksView";
import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { useAppDispatch } from "src/redux/hooks";
import { setCurrentCandidateSubmission } from "src/redux/reducers";
import { Submission } from "src/redux/types/exam-submission";

type View = "list" | "marks"

const CheckExams = () => {
  
  const [view, setView] = useState<View>("list");

  const dispatch = useAppDispatch();

  const onClickCheckPaper =(submission: Submission)=> {
    dispatch(setCurrentCandidateSubmission(submission))
    setView("marks");
  }

  const setDefaultView = () => {
    setView("list");
  }

  const renderView = () => {
    switch (view) {
      case "list": 
      return (
        <CheckExamListView onClickCheck={onClickCheckPaper}/>
      );
      case "marks": 
      return (
        <CheckExamMarksView defaultView={setDefaultView}/>
      )
    }
  }

  return (
    <DashboardDefaultContainer>
      {renderView()}
    </DashboardDefaultContainer>
  )
}

export default CheckExams