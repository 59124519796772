
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppAlert } from "../../../components/dialogue/Dialogue";
import { setCurrentCandidate } from "../../../redux/reducers";
import { Candidate } from "../../../redux/types/_candidate.type";
import { ViewCandidatesList } from "./ViewCandidatesList";
import { ViewCandidateDetails } from "./ViewCandidateDetails";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


type ViewComponent = "list" | "details"

export const ViewCandidates = () => {
  const dispatch = useDispatch();

  const [alertOpen, setAlertOpen] = useState(false);
  const [currentView, setCurrentView] = useState<ViewComponent>("list");

  const navigate = useNavigate();

  const setDefaultView = () => {
    setCurrentView("list");
  };

  // TODO: set candidate model instead of any
  const onTapDetails = (candidate: Candidate) => {
    // set current Candidate
    dispatch(setCurrentCandidate(candidate));
    setCurrentView("details");
  };

  const renderView = () => {
    switch (currentView) {
      case "list":
        return (
          <ViewCandidatesList
            onTapDetails={(candidate) => onTapDetails(candidate)}
          />
        );
      case "details":
        return (
          <ViewCandidateDetails
            onClose={setDefaultView}
          />
        );
      default:
        break;
    }
    return <></>;
  };

  return (
    <>
      <AppAlert
        open={alertOpen}
        title={`some title!`}
        message={"Are you sure to change?"}
        onClose={() => {
          setAlertOpen(false);
        }}
        onConfirm={() => {
          setAlertOpen(false);
        }}
      />
      <div className="container mx-auto px-4 mt-12">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          {currentView === "list" && (
            <div className="p-4 flex justify-between">
              <p className="text-2xl">View Candidates</p>
              <button
                className="bg-slate-200 rounded-lg hover:bg-amber-200 font-semibold btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => navigate("/front-desk")}
              >
                <ArrowBack />
                Back
              </button>
            </div>
          )}
          <div className="overflow-x-auto">{renderView()}</div>
        </div>
      </div>
    </>
  );
};
