import { z } from "zod";
import { Posts } from "../utils/posts";
import { states } from "./create-candidate";

export const EditSlotSchema = z.object({
  post: z.enum(Posts),
  state: z.enum(states as [string, ...string[]]),
  date: z.string(),
  startTime: z.string(),
  maxCapacity: z.string(),
  endtime: z.string(),
  zoom_link: z.string().url(),
  slotName: z.string().max(1),
});
