import { useState } from "react";
import { DashboardDefaultContainer } from "../../dashboard-default-container";
import { ManageAdmitHeader } from "./manage-admit-header";
import { ManageAdmitTable } from "./manage-admit-table";
import { useDispatch } from "react-redux";
import { Schedule } from "src/redux/types/admit-card";
import { setCurrentSchedule } from "src/redux/reducers";
import AdmitCardDetail from "./AdmitCardDetail";

type View = "table" | "detail";

export const ManageAdmitCardView = () => {
  const [view, setView] = useState<View>("table");
  const dispatch = useDispatch();

  const viewAdmit = (schedule: Schedule) => {
    dispatch(setCurrentSchedule(schedule));
    // setView("detail");
  };

  const defaultView = () => {
    setView("table");
  };

  const renderView = () => {
    switch (view) {
      case "table":
        return (
          <div>
            <ManageAdmitHeader />
            <ManageAdmitTable viewAdmit={viewAdmit} />
          </div>
        );
      case "detail":
        return <AdmitCardDetail onClose={defaultView} />;
      default:
        break;
    }
    return <></>;
  };

  return <DashboardDefaultContainer>{renderView()}</DashboardDefaultContainer>;
};
