import { AxiosResponse } from "axios";
import axios from "axios";
import { BASE_URL } from "./appConst";
import { ResponseModel } from "../types/common";
import axiosAuthRefresh from "axios-auth-refresh";

export const handleResponse = async (
  response: AxiosResponse
): Promise<ResponseModel> => {
  if (response.status === 200) {
    return {
      msg: "success",
      data: response.data.data,
    };
  }
  return { msg: "error occured" };
};

export const axiosAuth = () => {
  const token = localStorage.getItem("token") as string;

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Function to refresh token
  const refreshAuthLogic = (failedRequest: any) =>
    axios
      .post(`${BASE_URL}/refresh-tokens`, {
        refreshtoken: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        const newToken = response.data.data.response.AccessToken;
        localStorage.setItem("token", newToken);
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${newToken}`;
        return Promise.resolve();
      });

  axiosAuthRefresh(axiosInstance, refreshAuthLogic);

  return axiosInstance;
};
