import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialogue/radix-dialog";
import { CreateMultipleQuestionsForm } from "./create-multiple-questions-form";
import { CreateSingleQuestionForm } from "./create-single-question-form";
import { Button } from "src/components/buttons/shadcn-button";

interface CreateQuestionDialogProps {
  children: React.ReactNode;
}
export const CreateQuestionDialog = ({
  children,
}: CreateQuestionDialogProps) => {
  const [formType, setFormType] = useState<"single" | "multiple">("single");
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Create Questions</DialogTitle>
        </DialogHeader>
        <section>
          {formType === "single" ? (
            <CreateSingleQuestionForm />
          ) : (
            <CreateMultipleQuestionsForm />
          )}
          <div className="text-center">
            OR
          </div>
          <Button className="mt-2 w-full" onClick={() => {
            if(formType === "single") {
              setFormType("multiple")
            } else {
              setFormType("single")
            }
          }}>
            {formType === "single" ? "Upload CSV" : "Create question"}
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
};
