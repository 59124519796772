import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialogue/radix-dialog";
import { EditSlotForm } from "./edit-slot-form";
import { Slot } from "src/redux/types/slot";

interface EditSlotDialogProps {
  children: React.ReactNode;
  slot: Slot;
}
export const EditSlotDialog = ({ children, slot }: EditSlotDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Edit Slot</DialogTitle>
        </DialogHeader>
        <EditSlotForm slot={slot}/>
      </DialogContent>
    </Dialog>
  );
};
