import { Checkbox } from "@mui/material";
import { format } from "date-fns";
import { Button } from "src/components/buttons/shadcn-button";
import { DeleteQuestionDialog } from "./Delete-question-dialog";
import { EditQuestionDialog } from "./Edit-question-dialog";
import { Question } from "src/redux/types/questions";

interface TableRowProps {
  id: string;
  createdAt: string;
  updatedAt: string;
  question: Question;
  selected: boolean;
  onSelect: (id: string) => void;
  onClick: (id: number) => void;
}

export const TableRow = ({
  id,
  question,
  createdAt,
  updatedAt,
  selected,
  onSelect,
  onClick,
}: TableRowProps) => {
  const formatDateTime = (dateString: string) => {
    const dateObj = new Date(dateString);
    return format(dateObj, "dd-MM-yyyy HH:mm:ss");
  };

  return (
    <tr>
      <td>
        <Checkbox onChange={() => onSelect(id)} checked={selected} />
      </td>
      <td>{question.catagory}</td>
      <td>{question.type}</td>
      <td>{question.question}</td>
      <td>{question.option1}</td>
      <td>{question.option2}</td>
      <td>{question.option3}</td>
      <td>{question.option4}</td>
      <td>{question.answer}</td>
      <td>{question.marks}</td>
      <td>{question.part}</td>
      <td>{formatDateTime(createdAt)}</td>
      <td>{formatDateTime(updatedAt)}</td>
      <td>
        <EditQuestionDialog question={question}>
          <Button variant="default">Edit</Button>
        </EditQuestionDialog>
      </td>
      <td>
        <DeleteQuestionDialog onClick={() => onClick(Number(id))}>
          <button className="bg-red-500/80 hover:bg-red-500/60 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-white hover:text-black font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
            Delete
          </button>
        </DeleteQuestionDialog>
      </td>
    </tr>
  );
};
