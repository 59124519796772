import { useEffect, useState, useTransition } from "react";
import {
  DeleteEbookUserBulkApi,
  DeleteEbookUserSingleApi,
  GetEbookUsersApi,
  UpdateEbookUserStatusApi,
} from "../../../redux/api/_ebook-user.api";
import { useDispatch } from "react-redux";
import { setCurrentEBookuser, setEbookUsers } from "../../../redux/reducers";
import { Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DialogTrigger } from "@radix-ui/react-dialog";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "../../../components/dialogue/radix-dialog";
import { cn } from "../../../utils";
import { toast } from "react-toastify";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/input/Select";
import React from "react";
import { StatusToggleDiaog } from "./status-toggle-dialog";
import { DeleteUserDialog } from "./delete-user-dialog";
import { CandidateListPagination } from "../ManageCandidates/candidate-list-pagination";
import { TransactionDetailDialog } from "./txn-detail-dialog";
import { useDebounce } from "use-debounce";
import { TrashIcon } from "lucide-react";
import { SelectState } from "./select-state";
import { SelectStatus } from "./select-status";
import { SelectPost } from "./select-post";
import { AxiosError } from "axios";

export const BookUsersListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const name = searchParams.get("name") || "";
  const email = searchParams.get("email") || "";
  const post = searchParams.get("post") || "";
  const status = searchParams.get("status") || "";
  const pageSize = parseInt(searchParams.get("pageSize") || "25", 10);
  const currentPageNumber = parseInt(searchParams.get("page") || "1", 10)
  const state = searchParams.get("state") || "";

  const [selectedIds, setSelectIds] = useState<number[]>([]);

  const [failSafePageNumber, setFailSafePageNumber] = useState<number>();

  const { ebookUsers } = useAppSelector((state) => state.userReducer);

  const handleNextPage = () => {
    setFailSafePageNumber(currentPageNumber);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber + 1).toString(),
    });
    fetchEbookUsers().then((users) => {
      dispatch(setEbookUsers(users));
    });
  };

  const handlePrevPage = () => {
    if (currentPageNumber === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber -1).toString(),
    });
  };

  const handleJumpToPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
    fetchEbookUsers().then((users) => {
      if (!users || users.length === 0) {
        if (failSafePageNumber) {
          setSearchParams({
            ...Object.fromEntries(searchParams),
            page: failSafePageNumber.toString(),
          });
        }
        toast.error("No more candidates");
        return;
      }
      dispatch(setEbookUsers(users));
    });
  };

  const fetchEbookUsers = async () => {
    try {
      let queryString = "";
      if (email) {
        queryString = `${queryString}&email=${email}`;
      }
      if (name) {
        queryString = `${queryString}&name=${name}`;
      }
      if (post) {
        queryString = `${queryString}&post=${post}`;
      }
      if (state) {
        queryString = `${queryString}&state=${state}`;
      }
      const ebooksUsers = await GetEbookUsersApi(
        queryString,
        currentPageNumber,
        pageSize,
      );

      return ebooksUsers;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 500) {
          toast.error("Fetch failed please refresh");
        }
      }
      console.error(error);
    }
  };

  const deleteSelectedUsers = async () => {
    try {
      if (selectedIds.length === 0) {
        toast.error("No candidate selected");
        return;
      }
      const { status } = await DeleteEbookUserBulkApi(selectedIds);
      if (status === 200) {
        toast.success("Successfully deleted candidates");
        fetchEbookUsers().then((users) => {
          dispatch(setEbookUsers(users));
        });
      }
    } catch (error) {
      toast.error("Delete users unsuccessfull");
      console.error(error);
    }
  };

  const [isDeleteUserByIdPending, startDeleteUserByIdTransition] =
    useTransition();

  const deleteUserById = (id: number) => {
    try {
      startDeleteUserByIdTransition(() => {
        DeleteEbookUserSingleApi(id).then(({ status }) => {
          if (status === 200) {
            toast.success("User deleted successfully");
            fetchEbookUsers().then((users) => {
              dispatch(setEbookUsers(users));
            });
          }
        });
      });
    } catch (error) {
      toast.error("Error deleting user");
      console.error(error);
    }
  };

  const [isToggleEbookStatusPending, toggleEbookStatusTransition] =
    useTransition();

  const toggleEbookStatus = (id: number, status: "APPROVED" | "PENDING") => {
    try {
      toggleEbookStatusTransition(() => {
        UpdateEbookUserStatusApi([id], status).then((statusCode) => {
          if (statusCode === 200) {
            toast(`Successfully Changed the status to ${status} !`, {
              type: "success",
            });
            fetchEbookUsers().then((users) => {
              dispatch(setEbookUsers(users));
            });
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onActivatedCandidates = async (status: "APPROVED" | "PENDING") => {
    try {
      const values = selectedIds.values();
      if (values) {
        const candidateList = Array.from(values);
        const statusCode = await UpdateEbookUserStatusApi(
          candidateList,
          status
        );
        if (statusCode === 200) {
          toast(`Successfully Changed the status to ${status} !`, {
            type: "success",
          });
          fetchEbookUsers().then((users) => {
            dispatch(setEbookUsers(users));
          });
        }
      } else {
        toast("Please select candidates!", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCopyEmails = async () => {
    const values = selectedIds.values();
    if (values) {
      const candidateList = Array.from(values);
      let emailText = "";
      candidateList.forEach((candidate) => {
        const result = ebookUsers.find((item) => item.sl === candidate);
        if (result) {
          if (emailText.length > 0) {
            emailText = `${emailText},${result.emailId}`;
          } else {
            emailText = result.emailId;
          }
        }
      });
      if (emailText.length > 0) {
        await navigator.clipboard.writeText(emailText);
        toast(`Successfully Copied to Clipboard!`, {
          type: "success",
        });
      } else {
        toast(`Please select items to Copy to Clipboard!`, {
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    fetchEbookUsers().then((ebooksUsers) => {
      let filteredEbookUsers = ebooksUsers;
      if (status) {
        filteredEbookUsers = filteredEbookUsers.filter(
          (user) => user.status === status
        );
      }
      dispatch(setEbookUsers(filteredEbookUsers));
    });
  }, [debouncedSearchParams, currentPageNumber]);

  const DisplayCandidates = React.memo(() => {
    if (Array.isArray(ebookUsers)) {
      return (
        <tbody>
          {ebookUsers.map((candidate) => {
            const { sl, fullName, jobPost, paymentInfo, emailId, status } =
              candidate;
            return (
              <tr key={sl} className="max-w-screen-xl">
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={sl}
                      checked={selectedIds.includes(sl)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSelectIds((prevSelectedIds) => {
                          if (isChecked) {
                            return [...prevSelectedIds, sl];
                          } else {
                            return prevSelectedIds.filter((id) => id !== sl);
                          }
                        });
                      }}
                    />
                  </label>
                </th>
                <td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div>
                        <div className="font-bold">{fullName}</div>
                        <div className="text-sm opacity-50">{emailId}</div>
                      </div>
                    </div>
                  </td>
                </td>
                <td className="font-bold">{candidate.phoneNumber}</td>
                <td className="font-bold">{candidate.address.State}</td>
                <td>
                  <div className="font-bold text-center">{jobPost}</div>
                </td>
                <td>
                  <div className="font-bold">
                    <TransactionDetailDialog bookUser={candidate}>
                      <Button>
                        {Array.isArray(paymentInfo)
                          ? "Txn Details"
                          : "No Txn Details"}
                      </Button>
                    </TransactionDetailDialog>
                  </div>
                </td>
                <td>
                  <StatusToggleDiaog
                    fullName={fullName}
                    status={status as "PENDING" | "APPROVED"}
                    onClick={() =>
                      toggleEbookStatus(
                        sl,
                        status === "PENDING" ? "APPROVED" : "PENDING"
                      )
                    }
                    isPending={isToggleEbookStatusPending}
                  >
                    <button
                      className={cn(
                        "font-bold text-center px-3 py-2 rounded-md text-white w-[100px]",
                        status === "PENDING"
                          ? "bg-red-500 hover:bg-red-500/90"
                          : "bg-green-500 hover:bg-green-500/90"
                      )}
                    >
                      {status}
                    </button>
                  </StatusToggleDiaog>
                </td>
                <td>
                  <Button
                    onClick={() =>
                      navigate(
                        `/send-email?email=${encodeURIComponent(
                          candidate.emailId
                        )}`
                      )
                    }
                  >
                    Notification
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() => {
                      dispatch(setCurrentEBookuser(candidate));
                      const params = new URLSearchParams(
                        searchParams
                      ).toString();
                      navigate(`/ebook-user?${params}`);
                    }}
                  >
                    Details
                  </Button>
                </td>
                <td>
                  <DeleteUserDialog
                    fullName={fullName}
                    onClick={() => deleteUserById(sl)}
                    isPending={isDeleteUserByIdPending}
                  >
                    <button className="bg-red-500/70 hover:bg-red-500/50 h-9 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                      Delete
                    </button>
                  </DeleteUserDialog>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }

    return null;
  });

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="p-4 flex justify-between">
          <p className="text-2xl">Manage E-book Users</p>
          <div className="join">
            <button className="btn btn-xs sm:btn-sm md:btn-md join-item">
              <a href="/create-ebook-user">Create E-Book User</a>
            </button>
          </div>
        </div>
        <div className="p-4 flex flex-col">
          <div className="flex justify-between w-full items-center">
            <div className="relative w-[300px] px-2 py-3 border rounded-md">
              <input
                placeholder="Search by email"
                type="text"
                className="focus-within:outline-none"
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    email: e.target.value,
                  });
                }}
              />
              <div className="absolute top-1/2 -translate-y-1/2 right-1">
                <Search />
              </div>
            </div>
            <div className="relative w-[300px] px-2 py-3 border rounded-md">
              <input
                placeholder="Search by Name"
                type="text"
                className="focus-within:outline-none"
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    name: e.target.value,
                  });
                }}
              />
              <div className="absolute top-1/2 -translate-y-1/2 right-1">
                <Search />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <SelectState
                value={state}
                onValueChange={(state) => {
                  if (state === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      state: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    state,
                    page: "1",
                  });
                }}
              />
              <SelectPost
                value={post}
                onValueChange={(value) => {
                  if (value === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      post: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    post: value,
                    page: "1"
                  });
                }}
              />
              <SelectStatus
                value={status}
                onValueChange={(value) => {
                  if (value === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      status: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    status: value,
                  });
                }}
              />
              <Button
                onClick={() => {
                  setSearchParams({});
                }}
              >
                <TrashIcon />
              </Button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        if (selectedIds.length === ebookUsers.length) {
                          setSelectIds([]);
                        } else {
                          setSelectIds(
                            ebookUsers.map((candidate) => candidate.sl)
                          );
                        }
                      }}
                    />
                  </label>
                </th>
                <th>Name</th>
                <th>Contact Number</th>
                <th>State</th>
                <th>Post</th>
                <th className="text-center">Transaction</th>
                <th>Status</th>
                <th>Notification</th>
                <th>View</th>
                <th>Delete User</th>
              </tr>
            </thead>
            <DisplayCandidates />
          </table>
          <div className="flex justify-between items-center">
            <Select
              value={pageSize.toString()}
              onValueChange={(pageSize) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  pageSize,
                });
              }}
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Limit" />
              </SelectTrigger>
              <SelectContent className="bg-white w-[200px]">
                <SelectGroup>
                  {["25", "10"].map((pageSize) => (
                    <SelectItem
                      key={pageSize}
                      value={pageSize}
                      defaultValue={"25"}
                    >
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="flex-grow">
              <CandidateListPagination
                currentPageNumber={currentPageNumber}
                onNext={handleNextPage}
                onPrev={handlePrevPage}
                onJumpToPage={handleJumpToPage}
              />
            </div>
            <div className="join">
              <button
                className="btn btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onActivatedCandidates("PENDING")}
              >
                Deactivate
              </button>
              <button
                className="btn btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onCopyEmails()}
              >
                Copy Emails
              </button>
              <button
                className="btn btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onActivatedCandidates("APPROVED")}
              >
                Activate
              </button>
              <Dialog>
                <DialogTrigger asChild>
                  <button className="btn btn-xs sm:btn-sm md:btn-md join-item">
                    Delete Selected Users
                  </button>
                </DialogTrigger>
                <DialogContent className="bg-white">
                  <DialogHeader>
                    <DialogTitle className="text-black">
                      Confirm deletion
                    </DialogTitle>
                  </DialogHeader>
                  <section className="bg-white text-black space-y-4">
                    <h3 className="text-2xl">
                      Are you sure you want to delete this user?
                    </h3>
                    <div className="w-full max-w-screen-sm flex justify-between">
                      <DialogClose asChild>
                        <button
                          type="button"
                          className="px-3 py-2 rounded-md bg-sky-500 hover:bg-sky-500/90"
                        >
                          Cancel
                        </button>
                      </DialogClose>
                      <button
                        type="button"
                        className="px-3 py-2 rounded-md bg-red-500 hover:bg-red-500/90"
                        onClick={deleteSelectedUsers}
                      >
                        Confirm
                      </button>
                    </div>
                  </section>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
