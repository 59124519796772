import { NavigateBack } from "../../../../components/ui/navigate-back";
export const CheckExamHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Check Exams</p>
      <div className="flex gap-2">
        <NavigateBack href="/exams" />
      </div>
    </div>
  );
};