import { TableRow } from "./table-row";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteQuestionById, getQuestions } from "src/redux/api/_exams.api";
import { LimitSelector } from "./limit-selector";
import { ManageQuestionsPagination } from "./pagination";
import { Post, Posts } from "src/utils/posts";
import { useDebounce } from "use-debounce";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setQuestions } from "src/redux/reducers";
import { toast } from "react-toastify";
import { TrashIcon } from "lucide-react";
import { Button } from "@mui/material";

export const QuestionBankTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const questionTypes = ["OPTIONAL", "SHORT", "DESC"] as const;

  const type = searchParams.get("type") || "";
  const post = searchParams.get("post") || ("" as Post);
  const question_title = searchParams.get("question_title") || "";
  const answer = searchParams.get("answer") || "";

  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "25", 10);

  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state) => state.userReducer);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const fetchQuestions = async () => {
    let queryString = "";
    if (post !== "") {
      queryString = `${queryString}&post=${post}`;
    }
    if (type !== "") {
      queryString = `${queryString}&type=${type}`;
    }
    if (answer.trim() !== "") {
      queryString = `${queryString}&answer=${answer}`;
    }
    if (question_title.trim() !== "") {
      queryString = `${queryString}&question_title=${question_title}`;
    }
    try {
      const response = await getQuestions(currentPage, pageSize, queryString);
      dispatch(setQuestions(response));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchQuestions();
    window.addEventListener("questionCreated", () =>
      fetchQuestions()
    );
    return () => {
      window.removeEventListener("questionCreated", () =>
        fetchQuestions()
      );
    };
  }, [debouncedSearchParams]);

  const deleteQuestion = async (id: number) => {
    try {
      deleteQuestionById(id).then((response) => {
        if (response && response.message.toLowerCase() === "success") {
          toast.success("Question Deleted successfully!");
        }
        fetchQuestions();
      });
    } catch (error) {
      toast.error("Question not Deleted!");
      console.error(error);
    }
  };

  const handleSelect = (id: string) => {
    setSelectedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handlePrev = () => {
    if (currentPage === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPage -1).toString(),
    });
  };

  const handleNext = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPage + 1 ).toString(),
    });
    fetchQuestions();
  }

  const handleJump = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  }

  const onLimitChange = (newLimit) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      pageSize: newLimit,
    })
  }

  return (
    <div>
      <div className="p-4 flex justify-between items-center">
        <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Question"
              value={question_title}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  question_title: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Search by Answer"
              value={answer}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  answer: e.target.value,
                });
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <div className="flex flex-grow gap-3 items-end justify-end">
            <div className="flex justify-between">
              <select
                value={post}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    post: e.target.value,
                    page: "1",
                  });
                }}
                className="border p-1 rounded-md h-9"
              >
                <option value="">All Posts</option>
                {Posts.map((post) => (
                  <option key={post} value={post.toLowerCase()}>
                    {post}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between">
              <select
                value={type}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    type: e.target.value,
                    page: "1",
                  });
                }}
                className="border p-1 rounded-md h-9"
              >
                <option value="">Question Type</option>
                {questionTypes.map((type) => {
                  return (
                    <option key={type} value={type.toLowerCase()}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
            <Button
                onClick={() => {
                  setSearchParams({});
                }}
              >
                <TrashIcon />
              </Button>
          </div>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr className="text-base">
            <th></th>
            <th>Post</th>
            <th>Question type</th>
            <th>Question</th>
            <th>Option 1</th>
            <th>Option 2</th>
            <th>Option 3</th>
            <th>Option 4</th>
            <th>Answer</th>
            <th>Marks</th>
            <th>Part</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(questions) &&
            questions.map((row) => (
              <TableRow
                key={row.id}
                id={row.id}
                question={row}
                createdAt={row.createdAt}
                updatedAt={row.updatedAt}
                selected={!!selectedRows[row.id]}
                onSelect={handleSelect}
                onClick={deleteQuestion}
              />
            ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between py-5 px-2">
        <LimitSelector
          pageSize={pageSize}
          onValueChange={(newLimit)=> onLimitChange(newLimit)}
        />
        <ManageQuestionsPagination
          currentPage={currentPage}
          onPrev={handlePrev}
          onNext={handleNext}
          onJump={handleJump}
        />
      </div>
    </div>
  );
};
