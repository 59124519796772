/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  CloseButton,
  ButtonWithIcon,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";

import { CLIENT_URL } from "../../../utils";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColor";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { LocationBadge } from "../../../components/misc/LocationBagde";
import { useAppSelector } from "../../../redux/hooks";
import { LeadsTable } from "./LeadTable";
import { GetLeads } from "../../../redux/api";
import { setLeads } from "../../../redux/reducers";
import { LeadModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
interface DashboardProps {}

export const Leads: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { leads } = userReducer;

  useEffect(() => {
    onFetchLeads();
  }, []);

  const onFetchLeads = async () => {
    try {
      const data = await GetLeads();
      const userData = data as {
        message: string;
        data: { count: number; leads: LeadModel[] };
      };
      if (userData.data) {
        const { leads } = userData.data;
        dispatch(setLeads(leads));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
      </Container>
    );
  };

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>
                  <label>
                    <input type="checkbox" className="checkbox" />
                  </label>
                </th>
                <th>Name</th>
                <th>Address</th>
                <th>Applied Post</th>
                <th>Transaction</th>
                <th>Status</th>
                <th>Notification</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              <tr>
                <th>
                  <label>
                    <input type="checkbox" className="checkbox" />
                  </label>
                </th>
                <td>
                  <div className="flex items-center gap-3">
                    <div className="avatar">
                      <div className="mask mask-squircle w-12 h-12">
                        <img
                          src="/tailwind-css-component-profile-2@56w.png"
                          alt="Avatar Tailwind CSS Component"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">Jay Gogoi</div>
                      <div className="text-sm opacity-50">test@test.com</div>
                    </div>
                  </div>
                </td>
                <td>
                  Drakestrassa 22a, Lichterfielde, 12205
                  <br />
                  <span className="badge badge-ghost badge-sm">
                    Berlin, Germany
                  </span>
                </td>
                <td>RWO</td>
                <th>
                  <button className="btn btn-ghost btn-xs">details</button>
                </th>
                <th>
                  <button className="btn btn-sm">Small</button>
                </th>
                <th>
                  <button className="btn btn-sm">Notification</button>
                </th>
                <th>
                  <button className="btn btn-sm">Details</button>
                </th>
              </tr>
            </tbody>
            {/* foot */}
            <tfoot>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Job</th>
                <th>Favorite Color</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
