import { useEffect, useTransition } from "react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/input/Select";
import {
  CreateEbookUserSchema,
  states,
  statusEnums,
} from "../../../schemas/create-ebook-user";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "../../../components/input/input";
import { Loader2 } from "lucide-react";
import { Button } from "../../../components/buttons/shadcn-button";
import { SaveEbookUserSingleApi } from "../../../redux/api/_ebook-user.api";
import { toast } from "react-toastify";
import { Posts } from "../../../utils/posts";

export const CreateEbookUserForm = () => {
  const form = useForm<z.infer<typeof CreateEbookUserSchema>>({
    resolver: zodResolver(CreateEbookUserSchema),
  });

  const [isPending, startTransition] = useTransition();

  const onSubmit = (values: z.infer<typeof CreateEbookUserSchema>) => {
    startTransition(() => {
      SaveEbookUserSingleApi(values).then(({ status }) => {
        if (status === 200) {
          toast.success("User created");
        } else {
          toast.error("Something went wrong");
        }
      });
    });
  };

  const resetHandler = () => {
    if (form.formState.isSubmitSuccessful) {
      form.reset();
    }
  };

  useEffect(() => {
    resetHandler();
  }, [form.reset, form.formState]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-3 gap-x-3">
          <FormField
            control={form.control}
            name="fullName"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Full name</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's full name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="whatsappNumber"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>WhatsApp Number</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's whatsapp number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="post"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Choose Candidate's post</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's post" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Posts.map((post) => {
                      return (
                        <SelectItem key={post} value={post}>
                          {post}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-3">
          <FormField
            control={form.control}
            name="email"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email address</FormLabel>
                <FormControl>
                  <Input placeholder="Candidate's email address" {...field} />
                </FormControl>
                <FormDescription>
                  Updates will be mailed to this address.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="paymentAmount"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Payment Amount</FormLabel>
                <FormControl>
                  <Input placeholder="Amount paid" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="completePostalAddress"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>Postal Address</FormLabel>
              <FormControl>
                <Input placeholder="Candidate's postal address" {...field} />
              </FormControl>
              <FormDescription>
                Complete Postal Address (with house number if available)
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          defaultValue={undefined}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Choose Candidate's state</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Candidate's state" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {states.map((state) => {
                    return (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="pinCode"
          defaultValue=""
          render={({ field }) => (
            <FormItem>
              <FormLabel>PIN Code</FormLabel>
              <FormControl>
                <Input placeholder="Candidate's postal pincode" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-3 gap-x-3">
          <FormField
            control={form.control}
            name="transactionNumber"
            defaultValue=""
            render={({ field }) => (
              <FormItem>
                <FormLabel>Transaction Number</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Candidate's transaction number"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="transactionProof"
            defaultValue={undefined}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel>Upload Proof of the Transaction</FormLabel>
                <FormControl>
                  <Input
                    {...fieldProps}
                    placeholder="Transaction Proof Image"
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      onChange(event.target.files && event.target.files[0])
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            defaultValue={undefined}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Choose Candidate's status</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Candidate's status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {statusEnums.map((status) => {
                      return (
                        <SelectItem key={status} value={status}>
                          {status}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="w-full flex justify-center pt-5">
          <Button
            type="submit"
            // ref={submitBtnRef}
            disabled={isPending}
          >
            Submit
            {isPending && <Loader2 className="ml-3 animate-spin" />}
          </Button>
        </div>
      </form>
    </Form>
  );
};
