import { NavigateBack } from "../../../../components/ui/navigate-back";

export const ManageResultHeader = () => {
  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">Manage Results</p>
      <NavigateBack href="/exams" />
    </div>
  );
};
